
.DayPicker-Weekday {
  background-color: #ffffff;
  border: 1px solid $input-boxes;
  width: 14.285714285714286%;
}
.DayPicker-Weekday.DayPicker-Weekday--selected {
  background-color: $primary-light-1 !important;
  border-radius: 5px;
  margin-right: 5px;
  width: 40px;
  border: 1px solid $input-boxes;
}
.DayPicker-Weekday.DayPicker-Weekday--selected:disabled {
  background-color: $grey-color !important;
  border-radius: 5px;
  margin-right: 5px;
  width: 40px;
  border: 1px solid $input-boxes;
}