//  Tabs
.nav-tabs {
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: 0px;
  padding-bottom: 10px;
  font-size: 10px !important;
}

.c-header-searchbar, .c-header-searchbar .btn {
  font-size: 12px; 
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  padding: 0px;
  // margin-left: 0px;
}


.tab-content .tab-entry {
  background-color: $grey7-color;
  color: black;
}

// profile
.profile-name {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding: 0px;
  font-weight: bold;
  color: black;
  //background-color: $primary-color;
}

// account 
.c-header-nav .dropdown-item {
  min-width: 120px;
  font-size: 12px;
}

.dropdown-header {
  padding: 10px;
  font-size: 12px;
  border-radius: 5px 5px 0px 0px;
}

.nav-tabs .btn {
  padding: 0px;
}

.tab-content tr {
  background-color: $white3-color;
}
.nav-tabs .nav-link {
  color: #CCC;
  border-color: transparent;
  border-right-color: #c4c9d0;
  text-transform: uppercase;
  border-radius: 0px;
  padding: 20px 16px;

}
.nav-tabs .nav-item:nth-last-child(1) .nav-link {
  border-right-color: transparent !important;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
  border-right-color: #c4c9d0;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  border-right-color: #c4c9d0;
}

//  Tab entry
.tab-entry {
  background-color: $white2-color;
  margin-bottom: 5px;
}
.tab-entry-field {
  line-height: 40px;
}
.tab-entry-switch {
  line-height: 20px;
}
.tab-entry-switch .c-switch {
  margin-top: 7px;
}
.tab-entry .form-control-sm {
  margin-top: 6px;
  padding: 10px 0 10px 20px;
}
.tab-entry-field .btn-sm {
  padding: 2px 11px;
}
.tab-entry-switch .c-switch-slider {
  cursor: pointer !important;
}



@media (max-width: 415px) {
  .c-header-searchbar, .c-header-searchbar .btn {
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
  }

  .c-header .navbar {
    padding-right: 0px;
    margin-right: 0px;
  }


  .profile-name {
    display: inline;
  }
}

