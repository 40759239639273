//  Accordion
.accordion .card-header .triangle {
  background-image: none;
  background-image: initial;
  border-style: solid;
  border-width: 5px 5px 5px 0;
  border-color: transparent #000 transparent transparent;
  height: 5px;
  float: right;
  margin-top: 4px;
}

.accordion .open.card-header .triangle {
  transform: rotate(-90deg);
  margin-top: 7px;
  margin-left: 5px;
}
