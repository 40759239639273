//  Pagination
.pagination {
  margin-bottom: 0px;
}
.page-link {
  color: #8a93a2;
  border: 0px;
}
.page-item.active .page-link {
  color: #000;
  background-color: transparent;
  border: 0px;
}
