//  Filter
.filter-input-group {
  margin: 0px !important;
}
.filter-input-group {
  padding: 0px 5px !important;
}
.filter-tokenizer,
.filter-tokenizer.padding-for-clear-all {
  width: 100% !important;
  border-radius: 0px !important;
  padding: 7px !important;
}
.filter-tokenizer ul.typeahead-selector {
  border: 0px !important;
  box-shadow: 1px 1px 0px #888 !important;
  background-color: #fff !important;
}
.filter-tokenizer-list__item {
  text-transform: capitalize;
}
.filter-tokenizer ul.typeahead-selector li {
  background-image: initial !important;
}
.filter-tokenizer ul.typeahead-selector li.header {
  background-color: $primary-color !important;
  color: #000 !important;
}
.filter-tokenizer ul.typeahead-selector li a {
  width: 100% !important;
}
.filter-tokenizer ul.typeahead-selector .hover a,
.filter-tokenizer ul.typeahead-selector li a:hover,
.typeahead ul.typeahead-selector .hover a,
.typeahead ul.typeahead-selector li a:hover {
  background-color: $white3-color !important;
  color: #000 !important;
}
.filter-tokenizer, .filter-tokenizer.padding-for-clear-all {
  padding: 6.5px !important;
}
.filter-tokenizer .typeahead-token {
  background-image: initial !important;
  border-radius: 0px !important;
  margin: 0px !important;
  padding: 2px !important;
}
.typeahead-token-close {
  padding: 4px;
}
.filter-tokenizer .clear-all {
  right: 4px !important;
}
