// Base layout
html {
  background: $white2-color;
}
//   Body
body {
  font-family: "Arial";
  font-weight: normal;
  background: $white2-color;
}
body.modal-open {
  overflow: hidden;

}

body div{
  font-family: "Arial";
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Arial";
  font-weight: bold !important;
  margin-bottom: 0rem;
  margin-top: 0rem;
  padding: 0px;
}

h1, .h1 {
  font-size: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase !important;
  margin-left: 0px;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: $grey1-color;
}
a:hover {
  text-decoration: none;
  background-color: transparent;
  color: $grey1-color;
}

a.btn {
  font-size: 14px;
  letter-spacing: 0.2px;
  color: $grey1-color;
  text-transform: none;
}

a.btn:hover {
  color: black;
  text-decoration: none;
}
iframe {
  border: 0px;
}
:focus {
  outline: 0;
}
.mb-4, .my-4 {
  margin-bottom: 10px !important;
}
.mb-5, .my-5 {
  margin-bottom: 15px !important;
}

h1, h2, h4, .h1, .h2, .h4 {
  // margin-top: 5px;
  // margin-bottom: 0px;
  font-size: 14px !important;
  text-transform: uppercase;
  font-weight: bold;
}

h2.toolbar-title { 
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 0px;
}

p {
  margin-bottom: 0px;
  padding-bottom: 5px;
}

//   Main
.c-main {
  padding: 10px 10px 10px 10px;
}
.p-4 {
  padding: 15px 15px 15px 15px !important;
}
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  padding-right: 10px;
  padding-left: 10px;
}
@media (min-width: 768px) {
  .c-main > .container-fluid, 
  .c-main > .container-sm,
  .c-main > .container-md,
  .c-main > .container-lg,
  .c-main > .container-xl {
      padding-right: 15px;
      padding-left: 15px;
  }
}

//  Modal
.modal-xl {
  max-width: 100% !important;
  margin: 1rem !important;
}

//   App
.c-app {
  color: $black1-color;
  background: $white2-color;
}

.vertical-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.float-left {
  padding: 1px;
}
.float-left:nth-child(2) {
  margin-top: 1px;
  color: $grey1-color;

  div:nth-child(1) {
    margin-top: 2px;
  }
}

.float-left:nth-child(3) {
  margin-top: 1px;
  color: $grey1-color;

  div:nth-child(1) {
    margin-top: 2px;
  }
}
.float-left:nth-child(4) {
  margin-top: 1px;
  color: $grey1-color;

  div:nth-child(1) {
    margin-top: 2px;
  }
}

.ace_editor *{
  font-family : monospace !important;
  font-size: 16px !important;
  direction:ltr !important;
  text-align:left !important;
}

.bottom-space {
  margin-bottom: 10px;
}

@media (min-width: 576px) {  
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1280px) {
}
