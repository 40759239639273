
// cards
.production-order-card {
  padding: 10px 10px 5px 15px !important;
  margin: 8px 0px;
  border-radius: 5px;
  font-size: 12px;

  h4 {
    margin-top: 0px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  p {
    padding-bottom: 0px;
  }

  .btn-class {
    padding: 1px  !important;
    margin-left: 8px !important;
    margin-right: 5px !important ;
    margin-top:  -3px !important;
    width: 20px !important;
  }
}

.kanban-column {
  border-radius: 5px !important;
  }

  // .dashboard-widget .row button.btn.btn-primary.btn-sm, .dashboard-widget .row .btn-group-sm > button.btn.btn-primary {
  //   height: 40px !important;
  // }


 
  //  Kanban
  .react-kanban-column {
    background-color: black !important;
  }
  
  .react-kanban-column-header {
    text-transform: uppercase;
    padding-bottom: 0px !important;
    margin-top: 5px;
  }
  
  .production-order-card .btn {
    margin-top: -10px;
    background-color: transparent;
  }
  
  .production-order-card .btn:hover {
    color: black;
  }

  .fc .fc-col-header-cell-cushion {
    font-size: 10px !important;
  }

  .footerbutton {
    background-color: white;
    border-radius: 0px 0px 5px 5px;
  }

// .production-order-card.prioritized-backlog {
//   background-color: $grey-color-2 ;
// }

// .production-order-card.work-in-progress {
//   background-color: $blue-light-2 !important;
// }

// .production-order-card.validate {
//   background-color: $yellow-light-3 !important;
// }

.production-order-card.complete {
  background-color: $grey-color-2 !important;
}

.production-order-card.prioritized-backlog.workorder-card {
  background-color: $grey4-color !important;
}

.production-order-card.prioritized-backlog.productionorder-card {
  background-color: $blue3-color !important;
}

.production-order-card.prioritized-backlog.maintenanceorder-card {
  background-color: $orange3-color !important;
}

.production-order-card.prioritized-backlog.qualityorder-card {
  background-color: $red3-color !important;
}

.production-order-card.prioritized-backlog.testorder-card {
  background-color: $pink3-color !important;
}

.production-order-card.prioritized-backlog.reservedorder-card {
  background-color: $purple3-color !important;
}

.production-order-card.prioritized-backlog.stoppedorder-card {
  background-color: $yellow3-color !important;
}

.production-order-card.work-in-progress.workorder-card {
  background-color: $grey2-color !important;
}

.production-order-card.work-in-progress.productionorder-card {
  background-color: $blue1-color !important;
}

.production-order-card.work-in-progress.maintenanceorder-card {
  background-color: $orange1-color !important;
}

.production-order-card.work-in-progress.qualityorder-card {
  background-color: $red1-color !important;
}

.production-order-card.work-in-progress.testorder-card {
  background-color: $pink1-color !important;
}

.production-order-card.work-in-progress.reservedorder-card {
  background-color: $purple1-color !important;
}

.production-order-card.work-in-progress.stoppedorder-card {
  background-color: $yellow1-color !important;
}

.production-order-card.validate.workorder-card {
  background-color: $grey5-color !important;
}

.production-order-card.validate.productionorder-card {
  background-color: $blue5-color !important;
}

.production-order-card.validate.maintenanceorder-card {
  background-color: $orange5-color !important;
}

.production-order-card.validate.qualityorder-card {
  background-color: $red5-color !important;
}

.production-order-card.validate.testorder-card {
  background-color: $pink5-color !important;
}

.production-order-card.validate.reservedorder-card {
  background-color: $purple5-color !important;
}

.production-order-card.validate.stoppedorder-card {
  background-color: $yellow5-color !important;
}