// Print
@media print {
  html, body {
    background-color: #ffffff;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  table th, .table th, .table thead th {
    background-color: $primary-color;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
