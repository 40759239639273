
.slim-card-body {
    display: flex;
    justify-content: space-between;
    width: 50%;
    .form-group {
        width: 48%;
        input, select {
            height: 40px !important;
            border-radius: 5px !important;
        }
    }
  }
  
  .modal-footer {
    .btn-secondary {
        background-color: $cancel-color !important;
        color: black !important;
        height: 40px !important;
        font-size: 12px;
    }
  
    .btn-secondary:hover  {
      background-color: $cancel-hover !important;
    }
  
    .btn-primary {
      background-color: $primary-color;
      color: black !important;
      height: 40px !important;
      font-size: 12px;
      }
      .btn-primary:hover { 
          background-color: $primary-color-hover ;
      }
  
    .btn-danger {
        color: black !important;
        height: 40px !important;
        font-size: 12px;
    }
    .btn-danger:hover {
      background-color: $danger-color-hover;
    }
  }
  
  
  .modal-body{
  
       .nav-tabs {
           margin-bottom: 30px !important;
           margin-top: 10px !important;
       }
  
      .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
          padding: 0px 16px !important;
  
      }
  
    .custom-select {
      height: 40px !important;
      max-width: 100%;
      border-radius: 5px !important;
    }
    .tab-content {
      padding-top: -10px !important;
      margin-top: 0px;
  
        .card-body {
            background-color: white!important;
            padding: 0px 12px 10px 12px;
        }
  
        .actions-entry {
            background-color: $primary-light-4 !important;
            border-radius: 5px;
        }
  
        .btn.btn-secondary.btn-sm.btn-block, .btn-group-sm > .btn.btn-secondary.btn-block {
            background-color: $primary-light-2;
            border-color: $input-boxes;
        }
        .btn.btn-secondary.btn-sm.btn-block:hover, .btn-group-sm > .btn.btn-secondary.btn-block:hover {
          background-color: $primary-color;
          border-color: $input-boxes;
      }
  
      .btn {
          min-width: 40px;
          height: 40px;
      }
  
  
      .btn-success {
          background-color: $primary-light-3 !important;
          border: 0px;
          min-width: 40px;
          height: 40px;
          color: black;
      }
   
      .btn-success:hover { 
          background-color: $primary-color!important;
      }
    }
  
    .btn.btn-secondary.btn-sm.btn-block, .btn-group-sm > .btn.btn-secondary.btn-block {
      background-color: $primary-light-1 !important;
      border-color: $input-boxes;
      }
      .btn.btn-secondary.btn-sm.btn-block:hover, .btn-group-sm > .btn.btn-secondary.btn-block:hover{
          background-color: $primary-color-hover !important;
      }
  
      
      .actions-entry {
          background-color: $primary-light-3 !important;
          border-radius: 5px;
      }
  
      
  
    .select__control {
      height: 40px !important;
      border-radius: 5px !important;
    }
  
    .form-control {
      height: 40px !important;
      border-radius: 5px !important;
    }
   
    .CrossArea .square {
      border: none;
      border-radius: 5px !important;
    }
  
    .CrossArea .left .tag {
      border-radius: 5px !important;
      font-size: 11px;
      color: black; 
      border-color: $primary-light-1;
    }
  
    .CrossArea .right .tag {
      border-radius: 5px !important;
      font-size: 11px;
      color: black; 
      border-color: $primary-color-hover;
  
      .c-icon {
          color: $primary-color-hover;
          width: 14px;
      }
    }
  
    .square > label {
      margin-top: -10px;
      padding: 8px 8px 8px 15px;
      border: none;
      background-color: $primary-light-3;
      border-radius: 5px 5px 0px 0px!important;
      font-size: 11px;
    }
  
    .filter-tokenizer.padding-for-clear-all {
      height: 40px;
      border-radius: 5px !important;
    }
  }
  
  .Status__Filter--Component {
    .Form__Section--Top, .Form__Section--Bottom, .Form__Section--Footer {
        background-color: $grey-color;
        padding: 30px;
        margin-left: -40px;
        margin-right: -40px;
        margin-top: -15px;
    }
    .Form__Section--Footer {
        padding-bottom: 10px;
        padding-top: 0px;
    }
    .Form__Section--Bottom {
        //background-color: #F4F4F4;
        background-color: $grey-color;
  
        padding-top: 0px;
        .button-conditions:nth-child(even) {
            background-color: $grey-color;
        }
        input {
            border-color: $input-boxes!important;
            background-color: white;
            transition: background-color 1s;
            &:required {
                background-image: url('./../../../assets/icons/exclamation-mark.svg');
                transition: background-color 1s;
                background-color: rgba(255,0,0, 0.05);
                border-color: #E2574C;
                background-repeat: no-repeat;
                background-size: 20px;
                background-position: 95% 50%;
                padding-right: 40px;
            }
        }
        .btn {
            width: 40px;
            height: 40px;
            background-color: $primary-light-1;
            .c-icon {
               margin: 0px !important;
            }
        }
        .btn:hover {
          background-color: $primary-color-hover;
          color: black;
        }
        .c-switch-3d-primary .c-switch-input:checked + .c-switch-slider {
            background-color: $primary-light-4;
            &::before {
                background-color: $primary-light-2;
                border: 1px solid black
            }
        }
        .Buttons__Selection--Row {
            display: flex;
            justify-content: space-around;
            align-items: center;
            button {
                height: 25px;
                width: 25px;
                border-radius: 50%;
                outline: none;
                border: none;
                &.selected {
                    box-shadow: 0 0 6px 0 
                }
            }
        }
    }
    .Count__FormGroup, .SubmitUpdate__FormGroup--Buttons {
        display: flex;
        height: 100%;
        align-items: center;
    }
    .SubmitUpdate__FormGroup--Buttons {
        display: flex;
        justify-content: flex-end;
        button {
            width: 130px;
            height: 40px;
            &#Filter__Cancel--Button {
                background-color: $grey-color;
                margin-right: 8px;
                &:hover {
                    background-color: $white1-color;
                }
            }
            &#Filter__Add--Button {
                background-color: $primary-light-1;
                &:hover {
                    background-color: $primary-color-hover;
                }
            }
        }
  
        
    }
    .Count__FormGroup > label:last-child {
            margin-bottom: 0;
    }
    .Row__Table {
        margin-top: 20px;
    }
    
   
  
    table {
        border-radius: 5px !important;
        width: 100%;
        margin-bottom: 20px;
        tr {
            height: 40px !important;
            
            th {
                min-width: 100px;
                background-color: $primary-light-3 !important;
               font-weight: bold;
            }
            :nth-child(6) {
                width: 55%;
            }
            > :last-child {
                width: 120px;
                button {
                    background-color: transparent;
                    padding: 10px;
                    border: none;
                    &:focus {
                        outline: none;
                    }
                    .c-icon {
                        width: 20px;
                        height: 20px;
                    }
                    &:hover {
                        background-color: $primary-color-hover;
                    }
                    svg {
                        margin: 0;
                    }
                }
            }
        }
        tbody > tr {
           
        }
        td {
            text-transform: capitalize;
            
            &:nth-child(6) {
                justify-content: start;
            }
            &:last-child {
                display: flex;
                padding: 5px 12px 5px 5px;
            }
        }
    }
  
    .Status__Filter--Component .ant-table-container, .Status__Filter--Component .ant-table, .Status__Filter--Component .ant-table-wrapper, .Status__Filter--Component .ant-spin-nested-loading, .Status__Filter--Component .ant-spin-container, .Status__Filter--Component .ant-table-thead {
      border-radius: 5px !important;
    }
  }
  
  #save__widget--button:enabled {
    background-color: $primary-color;
  }
  
  
  .btn-primary {
      color: #000;
      background-color: $primary-color;
    }
    
    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary.focus,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      color: #000;
      background-color: $primary-color-hover;
      //border-radius: 5px;
    }
  
  