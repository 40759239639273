// If you want to override variables do it here
@import "variables";
//@import "variables_digidelta";
//@import "variables_singlecode";
//@import "variables_aralab";
//@import "variables_viplant";
//@import "variables_lumitio";
//@import "variables_qms";


//import '~rsuite/styles/index.less'; // or 'rsuite/dist/rsuite.min.css'
@import '~rsuite/dist/rsuite.min.css';

// Import Antd styles
@import '~antd/dist/antd.css';

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "./core/custom";
//@import "./clients/aralab";
//@import "./clients/viplant";
//@import "./clients/prosolia";
//@import "./clients/digidelta";
//@import "./clients/digidelta_dark";
//@import "./clients/lumitio";
//@import "./clients/singlecode";
//@import "./clients/qms";
