//  Tags
.CrossArea{
  &::after {
    content: '';
    display: block;
  }
  .square {
    border: 1px solid #E9E9E9;
    border-radius: 0px;
    padding: 5px;
    min-height: 30px;
  }
  .left {
    float: left;
    .tag {
      position: relative;
      margin: 3px;
      font-size: 13px;
      border: 1px dashed #cccccc;
      border-radius: 0px;
      padding: 0 8px;
      line-height: 30px;
      color: #666666;
      background: rgba(255, 255, 255, 0.7);
    }
  }
  .right {
    float: right;
    .tag {
      position: relative;
      margin: 3px;
      font-size: 13px;
      border: 1px dashed #9cc6f3;
      border-radius: 0px;
      padding: 0 8px;
      line-height: 30px;
      color: #666666;
      background: rgba(255, 255, 255, 0.7);
    }
    .delete {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      user-drag: none; 
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
}
.square > label {
  border-bottom: 1px solid #ccc;
  width: 100%
}

.DraggableTags {
  min-height: 100px;
}

.DraggableTags-tag {
  color: initial !important;
  float: left;
}
