

.equipment-widget {
    
  

    .row {
    
        background-color: transparent;
    }


    .rst__tree{
        margin-top: 10px;
    }

    .form-control {
        border-color: $black3-color;
        padding-left: 20px;
        height: 40px;
        color: black;
        border-radius: 5px !important;
    }

    .css-1uccc91-singleValue {
        margin-left: 0px;
    }

    .form-group.entit {
        margin-right: 10px;
        border-radius: 5px !important;
    }
   
    .form-control.form-control-sm.mr-sm-2 {
        height: 40px;
        width: 120px;
        border-color: $black3-color;
        border-radius: 5px !important;
    }

    .form-control.form-control-sm.mr-sm-2 span {
        font-weight: 800;
        border-radius: 5px !important;
    }

    .btn.btn-secondary.btn-sm {
        background-color: $primary-light-1 !important;
        color: black !important;
        border: 1px solid $primary-color !important;
    }
    .btn.btn-secondary.btn-sm:hover {
        background-color: $primary-color-hover !important;
    }

    .node-type-function .rst__rowContents {
        background-color: #FFDB72;
        border: 1px solid $black2-color;
        margin-left: -2px;
        box-shadow: none;
        height: 45px;
        border-radius: 5px !important;
    }

    .node-type-measurepoint .rst__rowContents {
        background-color: #BCE4FA;
        border: 1px solid $black2-color;
        margin-left: -2px;
        box-shadow: none;
        height: 45px;
        border-radius: 5px !important;
    }

    .node-type-group .rst__rowContents {
        background-color: $green2-color;
        border: 1px solid $black2-color;
        margin-left: -2px;
        box-shadow: none;
        height: 45px;
        border-radius: 5px !important;
    }

    .custom-select {
        border-radius: 5px !important;
    }

    .move-handle {
        height: 40px;
        width: 40px;
        background: #d9d9d9 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+) no-repeat center;
        border: solid #aaa 1px;
        cursor: move;
        border-radius: 1px;
        z-index: 1;
    }

    .move-block {
        cursor: move;
        border-bottom: 1px solid #eeeeee;
    }

    .rstcustom__collapseButton {
        display: none;
    }

    // entity
    .rst__rowContentsDragDisabled {    
        //box-shadow: 3px 2px $black5-color !important;
        box-shadow: none !important;
        border-radius: 0px !important;
        border: 1px solid $black2-color !important ;   
    }

    //botão collaps e expand
    /*
    button.rst__expandButton:first-of-type , button.rst__collapseButton:first {
    background-position-x: 10px;
    } */

    button.rst__expandButton {
        background-color: white;
        background-size: 70%;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMzQxLjQgMzQxLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM0MS40IDM0MS40OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBvbHlnb24gcG9pbnRzPSIxOTIsMTQ5LjQgMTkyLDAgMTQ5LjQsMCAxNDkuNCwxNDkuNCAwLDE0OS40IDAsMTkyIDE0OS40LDE5MiAxNDkuNCwzNDEuNCAxOTIsMzQxLjQgMTkyLDE5MiAzNDEuNCwxOTIgDQoJCQkzNDEuNCwxNDkuNCAJCSIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
    }
    button.rst__collapseButton {
        background-color: white;
        background-size: 70%;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cmVjdCB5PSIyMjIuNjA5IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjY2Ljc4MyIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
    }

    .rst__tree {
        width: 100%;
    }

    .rst__collapseButton:hover:not(:active), .rst__expandButton:hover:not(:active) {
        background-size: 65% !important;
        height: 18px;
        width: 18px
    }

    .tree-select {
        width: 200px;
    }

    .filter-toolbar.dropdown-right .filter-toolbar-field-dropdown {
        background-color: #ffffff;
        border: 1px solid #000;
        position: absolute;
        top: 38px;
        left: initial;
        right: 0px;
        z-index: 9999999;
        min-width: 260px;
    }
    
    .tree-container {
        height: 100%;
        padding: 25px 0px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        
        label {
            padding-left: 10px;
            padding-right: 10px;
            display: block;
        }
    }

    .components-container {
        height: 100%;
        padding: 25px;
        background-color: $white3-color;
        border-radius: 5px;

        .filter-toolbar {
            border-bottom: 0px;
        }

        .table th, .table th, .table thead th {
            background-color: transparent;
            border-radius: 5px !important;
        }
    }
}
