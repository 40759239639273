//  Callout
.c-callout {
  border-radius: 0px;
}
.c-callout-primary {
  border-left-color: $primary-color !important;
}

.c-callout-secondary {
  border-left-color: $secondary-color !important;
}

.c-callout-info {
  border-left-color: $info-color !important;
}

.c-callout-success {
  border-left-color: $success-color !important;
}

.c-callout-warning {
  border-left-color: $warning-color !important;
}

.c-callout-danger {
  border-left-color: $danger-color !important;
}
