
.genericinfocard-component {
    .card {
        height: 100%;
        margin-bottom: 10px;
    }
    .card-body {
        padding: 15px 0px 15px 5px;
    }

    .genericinfocard-title {
        padding: 10px 15px 10px 10px;
        font-weight: bold;
        font-size: 12px;
        font-family: Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        background-color: $primary-color;
        max-height: 50px;
        align-items: center;
        color: #ffffff;

        .btn {
            padding: 5px 10px 5px 5px;
            text-align: left;
        }
        .btn:last-child {
            padding: 5px 5px 5px 5px;
        }
        .genericinfocard-title-icon {
            float: right;
            color: $primary-color-text;
        }
    }

    .genericinfocard-title {
        display: flex;
        flex-direction: row;
        height: 50px;
        letter-spacing: 1px;
    }


    .genericinfocard-title-title {
        flex: 1;
        margin-left: 10px;
    }


    .genericinfocard-body {
        font-weight: normal;
        font-family: Arial, Helvetica, sans-serif;

        > div {
            //display: inline-block;
            //margin-right: 10px;
        }
        .genericinfocard-inner-body {
            //min-width: 250px;
            margin: 0px 0px 10px 0px;
            font-weight: bold;
            font-size: 12px;
            .c-icon {
                margin-right: 5px;
                margin-bottom: 4px;
            }

            .input-group .form-control {
                border-radius: 5px 0px 0px 5px;
            }

            .input-group .btn {
                padding: 10px 10px;
                border-radius: 0px 5px 5px 0px;
            }

            .Control__Mode--InputGroup {
                display: flex;
                align-items: center;
                position: relative;
                .Control__Status {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    left: -40px;
                }
                .State__Icon {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .input-group {
            .btn {
                height: 40px;
                width: 40px;
                border: 1px solid #B5B5B5;


                .genericinfocard-title-icon {
                    width: 21px;
                    height: 21px;
                    margin-top: -2px;
                    margin-right: 2px !important;
                    margin-left: 0px;
                    padding: 0px;
                }
            }
        }
    }
}

// .Create__Infocard--Container {
//     .nav-tabs {
//         margin: 20px 20px 0px 20px;
//         width: fit-content;
//         padding-bottom: 0px;
//         > :first-child {
//             border-right: 2px solid $black1-color;
//         }
//         .active {
//             font-weight: bold;
//         }
//     }

//     .nav-tabs .nav-link {
//         padding: 0px 10px;
//     }
    
//     .tab-content {
//         width: -webkit-fill-available;
//     }

//     .Select__control {
//                 border-radius: 0px;
//                 height: 50px;
//     }
//     .Create__Interfaces--Form, .Create__Links--Form {
//         background-color: $white2-color;
//         padding: 16px;
//         margin-bottom: 16px;
//         &.editing {
//             background-color: $green4-color;
//         }
//         .Actions--form {
//             display: flex;
//             align-items: center;
//             .ant-popover-disabled-compatible-wrapper {
//                 width: 100%;
//             }
//             button {
//                 border: none;   
//                 outline: none;
//                 width: 100%;
//                 min-width: 100px;
//                 max-width: 200px;
//                 height: 50px;
//                 &.Cancel--button {
//                     background-color: $secondary-color;
//                     margin-right: 10px; 
//                     &:hover {
//                         background-color: $secondary-color-hover;
//                     }
//                 }
//                 &#Add--button {
//                     background-color: $primary-color;
//                     &:hover {
//                         background-color: $primary-color-hover;
//                     }
//                     &:disabled {
//                         cursor: not-allowed;
//                         background-color: $green5-color;
//                     }
//                 }
//             }
//         }
//     }
//     .Actions__Table--Row {
//         button {
//             background-color: transparent;
//             padding: 10px;
//             width: 40px;
//             height: 40px;
//             border: none;
//             &:focus {
//                 outline: none;
//             }
//             &:hover {
//                 background-color: $primary-color;
//             }
//             &.editing {
//                 background-color: $primary-color;
//             }
//         }
//     }
//     th {
//         background-color: $green3-color !important;
//         border-bottom: 0 !important;
//     }
//     tr {
//         background-color: $white2-color;
//         &.editing {
//             background-color: $white3-color;
//         }
//     }
//     .Map__Results--Column {
//         display: flex;
//         align-items: center;
//         margin-top: 10px;
//         span {
//             padding-left: 10px;
//             padding-right: 10px;
//         }
//     }

//     .ant-table-wrapper, .nav-tabs {
//         margin-bottom: 16px;
//     }
//     .nav-link {
//         font-size: 12px;
//         padding-left: 20px;
//         text-transform: uppercase;
//         &.active {
//             font-weight: bold;
//         }
//     }
// }

// .Map__Results--Modal {
//     width: fit-content !important;
//     table {
//         width: 100%;
//         border: 1px solid $black4-color;
//         border-radius: 5px;
//         overflow: hidden;
//         th {
//             background-color: #2B4D52;
//             color: white;
//             font-size: 11px;
//             letter-spacing: 1px;
//         }
//         tr {
//             input {
//                 border: none;
//             }
//             border-bottom: 1px solid $black4-color;
//         }
//         button {
//             padding: 0;
//             background-color: transparent;
//             border: none;
//             outline: none;
//         }
//         .Modal__Actions {
//             width: 50px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             .warning {
//                 margin: auto;
//                 vertical-align: -webkit-baseline-middle;
//                 > svg {
//                     color: $yellow1-color;
//                 }
//             }
//         }  
//     }
// }
// .ant-modal-footer {
//     .ant-btn {
//         border-radius: 5px;
//         text-transform: uppercase;
//         font-weight: bold;
//         font-size: 12px;
//         border: 2px solid #5797A0;
//         color: #5797A0;
//     }
//     .ant-btn:hover { 
//         border-radius: 5px;
//         text-transform: uppercase;
//         font-weight: bold;
//         font-size: 12px;
//     }
//     .ant-btn-primary {
//         background-color: #5797A0 !important;
//         border-radius: 5px;
//         text-transform: uppercase;
//         font-weight: bold;
//         font-size: 12px;
//         border: 0px;
//         color: white;
//     }
// }

// .Modal__Error--Message {
//     display: flex;
//     align-items: center;
//     p {
//         padding: 0;
//         margin: 6px;
//     }
//     button {
//         background-color: transparent;
//         border: none;
//         outline: none;
//     }   
//     td {
//         min-width: 50px !important;
//     }
// }

@media (max-width: 719px) {
    .genericinfocard-component .genericinfocard-title {
      padding-left: 20px !important; 
    }
  
    .genericinfocard-component .genericinfocard-title .dropdown .btn::after {
      border-style: solid;
      border-width: 5px 5px 5px 0;
      border-color: transparent #fff transparent transparent;
      display: block;
      flex: 0 1 8px;
      height: 8px;
      width: 8px;
      content: "";
      position: absolute;
      left: -10px;
      top: 8px;
      background-repeat: no-repeat;
      background-position: center;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
      -webkit-transform: rotate( 
        -180deg
        );
    }
    .genericinfocard-component .genericinfocard-title.show .dropdown .btn::after {
      top: 10px;
      -webkit-transform: rotate( 
    -90deg
    );
      transform: rotate( 
    -90deg
    );
    }
  }