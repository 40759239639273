
//  Grid component
.react-grid-item.cssTransforms {
  transition-property: none;
}
.animated .react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item {
  border: 1px solid #9f9fa7;
}
.react-resizable-hide {
  border: 0px solid #9f9fa7;
}
.react-grid-item > .react-resizable-handle {
  z-index: 99999;
}
