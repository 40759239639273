//  Form
.form-group {
  /*
  display: inline-block;
  min-width: 200px;
  margin-right: 50px;
  */
  margin-bottom: 15px;
  //background-color: white;
}
.text-left .m-0 .p-0 .btn .btn-link {
  background-color: red;
}

.form-control {
  border-radius: 0px;
  border-color: $black3-color;
  padding-left: 19px;
  height: 40px;
}
.form-control:focus {
  color: $black1-color;
  border-color: $black1-color;
  box-shadow: initial;
}
.input-group .form-control {
  border-right: 0px;
}
.input-group-text {
  padding: 0.3rem 0.75rem;
}
.input-group .input-group-prepend .input-group-text {
  background-color: transparent;
  border-radius: 0px;
  color: $black3-color;
  border-color: $black3-color;
  border-right: 0px;
}
.input-group .input-group-append .input-group-text {
  background-color: transparent;
  border-radius: 0px;
  color: $black3-color;
  border-color: $black3-color;
  border-left: 0px;
}
label {
  font-size: 12px;
  margin-bottom: 10px;
  padding-left: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.custom-select {
  display: block;
  border-radius: 0px;
  border-color: $black3-color;
  padding: 0px 0px 0px 20px;
  height: 40px;
  color: black;
  background-color: white !important;
  cursor: pointer !important;
  display: block;
}
.custom-select:focus {
  background-color: white !important;
  border-color: $black2-color !important;
  box-shadow: initial !important;
  cursor: pointer !important;
}
.custom-select option {
  padding: 10px 0px 10px 20px !important;
}
.select__control {
  border-radius: 0px !important;
  border-color: $black3-color !important;
  padding: 0px 0px 0px 20px !important;
  box-shadow: initial !important;
  min-width: 140px;
}
.select__control:focus{
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: initial !important;
}

.is-invalid .select__control {
  border-color: #e55353 !important;
}

.select__value-container {
  padding: 0px 0px !important;
}
.select__indicator {
  padding: 9px !important;
}
.select__menu-list {
  border-radius: 0px;
  padding: 0px !important;
  border: 1px solid black;
  border-top: 0px;
}
.select__option {
  padding: 10px 0px 10px 20px !important;
}
.select__option--is-selected {
  color: $black4-color !important;
  background: $black5-color !important;
}
.select__option--is-focused {
  color: $black1-color !important;
  background: $black5-color !important;
}
.select__menu-notice--no-options {
  padding: 10px 20px 10px 20px !important;
}
.customReactSelectMenu .btn {
  padding: 8px;
  border: 1px solid black;
  border-top-width: 0px;
  border-radius: 0px;
}
.form-check-input {
  position: initial;
}
//  Signin Form
.signin-form h1 {
  margin-bottom: 15px;
  position: relative;
  height: 200px;
}

.signin-form h1:after {
  content: '';
  font-size: 15px;
  background-image: url(/images/lumitiologo_large.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
//    background-color: blue;
  left: 0px;
  right: 0px;
  top: -50px;
  width: 100%;
  height: 280px;
}

.signin-form h1 center:after {
content: '';
}

.signin-form .input-group {
margin-bottom: 40px !important;
//margin-top: -20px;
}

.signin-form .form-control {
border-left: 0px;
border-top: 0px;
border-right: 0px;
border-bottom: 1px solid #000;
background-color: transparent !important;
font-size: 14px;
font-weight: bold;
color: #000;
padding-left: 0px;
padding-bottom: 25px;
}

.signin-form .form-control::placeholder {
color: #000;
text-transform: uppercase;
}

.signin-form .input-group-append {
display: none;
}

.signin-form .btn-block {
width: auto;
margin: auto;
text-transform: capitalize;
}

#footer-login {
  width: 100%;
  background-color: white;
  height: 50px;
  margin-top: -50px;
  color:#223A44;
  position: absolute;
  display: block;
}

#footer-login p {
  display: inline-block;
  margin-left: 40px;
  margin-top: 15px;
  font-size: 12px;
}

#footer-login p a {
 text-decoration: none;
}

#facebook {
  width: 32px;
  height: 32px;
  //background-color: transparent;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzRhOTBlMjsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzJiNGQ1MiI+PHBhdGggZD0iTTg2LDE0LjMzMzMzYy0zOS41ODE1LDAgLTcxLjY2NjY3LDMyLjA4NTE3IC03MS42NjY2Nyw3MS42NjY2N2MwLDM1LjkyNjUgMjYuNDY2NSw2NS41OTY1IDYwLjk1MjUsNzAuNzc4di01MS43ODYzM2gtMTcuNzMwMzN2LTE4Ljg0MTE3aDE3LjczMDMzdi0xMi41MzQ1YzAsLTIwLjc1NDY3IDEwLjExMjE3LC0yOS44NjM1IDI3LjM2MjMzLC0yOS44NjM1YzguMjYzMTcsMCAxMi42Mjc2NywwLjYwOTE3IDE0LjY5ODgzLDAuODg4Njd2MTYuNDQwMzNoLTExLjc2NzY3Yy03LjMyNDMzLDAgLTkuODgyODMsNi45NDQ1IC05Ljg4MjgzLDE0Ljc3MDV2MTAuMjk4NWgyMS40NjQxN2wtMi45MDk2NywxOC44NDExN2gtMTguNTQ3MzN2NTEuOTM2ODNjMzQuOTgwNSwtNC43MzcxNyA2MS45NjMsLTM0LjY1MDgzIDYxLjk2MywtNzAuOTI4NWMwLC0zOS41ODE1IC0zMi4wODUxNywtNzEuNjY2NjcgLTcxLjY2NjY3LC03MS42NjY2N3oiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==') 50% 50% no-repeat;
  background-size: 100%;
  border: none;
  float:  right;
  margin-right: 40px;
  margin-top: 9px;

  a {
    width: 32px;
    height: 30px;
    display: inline-block;
  }
}

#linkedin {
  width: 32px;
  height: 32px;
  //background-color: transparent;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzRhOTBlMjsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzJiNGQ1MiI+PHBhdGggZD0iTTEzNi4xNjY2NywyMS41aC0xMDAuMzMzMzNjLTcuOTE5MTcsMCAtMTQuMzMzMzMsNi40MTQxNyAtMTQuMzMzMzMsMTQuMzMzMzN2MTAwLjMzMzMzYzAsNy45MTkxNyA2LjQxNDE3LDE0LjMzMzMzIDE0LjMzMzMzLDE0LjMzMzMzaDEwMC4zMzMzM2M3LjkxOTE3LDAgMTQuMzMzMzMsLTYuNDE0MTcgMTQuMzMzMzMsLTE0LjMzMzMzdi0xMDAuMzMzMzNjMCwtNy45MTkxNyAtNi40MTQxNywtMTQuMzMzMzMgLTE0LjMzMzMzLC0xNC4zMzMzM3pNNjQuNSwxMjEuODMzMzNoLTE4LjA4MTV2LTUwLjE2NjY3aDE4LjA4MTV6TTU1LjE0MDMzLDYyLjQ3MTgzYy01LjUyNTUsMCAtOS4yMTYzMywtMy42ODM2NyAtOS4yMTYzMywtOC42YzAsLTQuOTE2MzMgMy42ODM2NywtOC42IDkuODI1NSwtOC42YzUuNTI1NSwwIDkuMjE2MzMsMy42ODM2NyA5LjIxNjMzLDguNmMwLDQuOTE2MzMgLTMuNjgzNjcsOC42IC05LjgyNTUsOC42ek0xMjksMTIxLjgzMzMzaC0xNy41MDF2LTI3LjQxOTY3YzAsLTcuNTgyMzMgLTQuNjY1NSwtOS4zMzEgLTYuNDE0MTcsLTkuMzMxYy0xLjc0ODY3LDAgLTcuNTgyMzMsMS4xNjgxNyAtNy41ODIzMyw5LjMzMWMwLDEuMTY4MTcgMCwyNy40MTk2NyAwLDI3LjQxOTY3aC0xOC4wODE1di01MC4xNjY2N2gxOC4wODE1djcuMDAxODNjMi4zMjkxNywtNC4wODUgNi45OTQ2NywtNy4wMDE4MyAxNS43NDUxNywtNy4wMDE4M2M4Ljc1MDUsMCAxNS43NTIzMyw3LjAwMTgzIDE1Ljc1MjMzLDIyLjc0N3oiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==') 50% 50% no-repeat;
  background-size: 100%;
  border: none;
  float:  right;
  margin-top: 9px;
  margin-right: 15px;
  
  a {
    width: 32px;
    height: 30px;
    display: inline-block;
  }
}

.btn.btn-secondary.btn-sm {
  background-color: white;
  // border: 1px solid $black2-color;
}

.addFieldButton {
  width: 110px;
  background-color: $primary-color !important;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 9px 9px 9px 9px;
}


/*
.submitButton {
  padding: 10px 17px 10px 17px;
  margin-left: 10px;
  margin-bottom: 10px;
}
*/

.btn.btn-secondary.btn-sm.btn-block {
  padding: 10px 17px 10px 17px;
  background-color: $grey5-color;
  width: 140px;
  display: inline-block; 
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.updateFieldButton.btn.btn-secondary.btn-sm {
  background-color: $primary-color;
}


