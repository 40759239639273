//  Time Picker
.rc-time-picker {
  display: block !important;
}
.rc-time-picker-input {
  border-radius: 0px !important;
  border-color: #848484 !important;
  padding: 19px 0 19px 20px !important;
}
.rc-time-picker-input:focus {
  color: $black1-color;
  border-color: $black2-color;
  box-shadow: initial;
}
.input-group .rc-time-picker-input {
  border-right: 0px;
}
.rc-time-picker-clear {
  top: 14px !important;
}
.rc-time-picker-panel-inner {
  border-radius: 0px !important;
  box-shadow: 0 0 0 !important;
  border: 0px solid #848484 !important;
  top: 0px !important;
  background-color: transparent !important;
}
.rc-time-picker-panel-input-wrap {
  padding: 14px 0 15px 20px !important;
  border-bottom: 0px solid #848484 !important;
}
.rc-time-picker-panel-combobox {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #848484;
}