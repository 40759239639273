
.hierarchy-widget {
    .navbar {
        z-index: 999999;
    }
    
    .bg-light {
        border-radius:  5px;
    }

    .rst__tree{
        margin-top: 10px;
    }

    .form-control {
        border-color: $black3-color;
        padding-left: 20px;
        height: 35px !important;
        color: black;
    }

    .css-1uccc91-singleValue {
        margin-left: 0px;
    }

    label {
        padding-left: 10px;
        padding-right: 10px;
        display: block;
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

    // select estranho
    .basic-single .select__control, .basic-single .select__indicators, .css-yk16xz-control {
        height: 35px !important;
        border-radius: 5px !important;
        font-size: 12px !important;
        
    }

    .select__value-container {
        text-transform: none;
    }

    .custom-select {
        height: 35px !important;
        border-radius: 5px;
        font-size: 12px !important;
    }

    // select entity
    .form-group.entit {
        margin-right: 10px;
        height: 35px !important;
        font-size: 12px !important;
    }
    

    .form-control.form-control-sm.mr-sm-2 {
        height: 35px !important;
        width: 120px;
        border-color: $black3-color;
        height: 35px !important;
        font-size: 12px !important;
        border-radius: 5px !important;
        color: black !important;
    }

    .form-control.form-control-sm.mr-sm-2 span {
        font-weight: 800;
    }

    .btn.btn-secondary.btn-sm {
        background-color: white;
        // border: 1px solid $black2-color;
        font-size: 12px !important;
        font-weight: 100;
        text-transform: none;
    }

    .btn.btn-secondary.btn-sm:hover { 
        background-color: $primary-color;
    }

    .node-type-function .rst__rowContents {
        background-color: #FFDB72;
        border: 1px solid $black2-color;
        margin-left: -2px;
        border-radius: 0px;
        box-shadow: none;
        height: 45px;
    }

    .node-type-measurepoint .rst__rowContents {
        background-color: #BCE4FA;
        border: 1px solid $black2-color;
        margin-left: -2px;
        border-radius: 0px;
        box-shadow: none;
        height: 45px;
    }

    .node-type-group .rst__rowContents {
        background-color: $green2-color;
        border: 1px solid $black2-color;
        margin-left: -2px;
        border-radius: 0px;
        box-shadow: none;
        height: 45px;
    }

    .node-type-group > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMzg0IDM4NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzg0IDM4NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGc+DQoJCTxnPg0KCQkJPHJlY3QgeD0iMCIgeT0iMjc3LjMzMyIgd2lkdGg9IjM4NCIgaGVpZ2h0PSI0Mi42NjciLz4NCgkJCTxyZWN0IHg9IjAiIHk9IjE3MC42NjciIHdpZHRoPSIzODQiIGhlaWdodD0iNDIuNjY3Ii8+DQoJCQk8cmVjdCB4PSIwIiB5PSI2NCIgd2lkdGg9IjM4NCIgaGVpZ2h0PSI0Mi42NjciLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
        background-size: 45%;
        background-repeat: no-repeat;
        background-color: white;
    }

    .node-type-measurepoint.node-operation-add > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMzg0IDM4NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzg0IDM4NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGc+DQoJCTxnPg0KCQkJPHJlY3QgeD0iMCIgeT0iMjc3LjMzMyIgd2lkdGg9IjM4NCIgaGVpZ2h0PSI0Mi42NjciLz4NCgkJCTxyZWN0IHg9IjAiIHk9IjE3MC42NjciIHdpZHRoPSIzODQiIGhlaWdodD0iNDIuNjY3Ii8+DQoJCQk8cmVjdCB4PSIwIiB5PSI2NCIgd2lkdGg9IjM4NCIgaGVpZ2h0PSI0Mi42NjciLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
        background-size: 45%;
        background-repeat: no-repeat;
        background-color: white;
    }

    // não sei para que funciona esta parte
    .node-type-function > .rst__moveHandle {
        background: white url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYWxjdWxhdG9yIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jYWxjdWxhdG9yIGZhLXctMTQgZmEtM3giPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQwMCAwSDQ4QzIyLjQgMCAwIDIyLjQgMCA0OHY0MTZjMCAyNS42IDIyLjQgNDggNDggNDhoMzUyYzI1LjYgMCA0OC0yMi40IDQ4LTQ4VjQ4YzAtMjUuNi0yMi40LTQ4LTQ4LTQ4ek0xMjggNDM1LjJjMCA2LjQtNi40IDEyLjgtMTIuOCAxMi44SDc2LjhjLTYuNCAwLTEyLjgtNi40LTEyLjgtMTIuOHYtMzguNGMwLTYuNCA2LjQtMTIuOCAxMi44LTEyLjhoMzguNGM2LjQgMCAxMi44IDYuNCAxMi44IDEyLjh2MzguNHptMC0xMjhjMCA2LjQtNi40IDEyLjgtMTIuOCAxMi44SDc2LjhjLTYuNCAwLTEyLjgtNi40LTEyLjgtMTIuOHYtMzguNGMwLTYuNCA2LjQtMTIuOCAxMi44LTEyLjhoMzguNGM2LjQgMCAxMi44IDYuNCAxMi44IDEyLjh2MzguNHptMTI4IDEyOGMwIDYuNC02LjQgMTIuOC0xMi44IDEyLjhoLTM4LjRjLTYuNCAwLTEyLjgtNi40LTEyLjgtMTIuOHYtMzguNGMwLTYuNCA2LjQtMTIuOCAxMi44LTEyLjhoMzguNGM2LjQgMCAxMi44IDYuNCAxMi44IDEyLjh2MzguNHptMC0xMjhjMCA2LjQtNi40IDEyLjgtMTIuOCAxMi44aC0zOC40Yy02LjQgMC0xMi44LTYuNC0xMi44LTEyLjh2LTM4LjRjMC02LjQgNi40LTEyLjggMTIuOC0xMi44aDM4LjRjNi40IDAgMTIuOCA2LjQgMTIuOCAxMi44djM4LjR6bTEyOCAxMjhjMCA2LjQtNi40IDEyLjgtMTIuOCAxMi44aC0zOC40Yy02LjQgMC0xMi44LTYuNC0xMi44LTEyLjhWMjY4LjhjMC02LjQgNi40LTEyLjggMTIuOC0xMi44aDM4LjRjNi40IDAgMTIuOCA2LjQgMTIuOCAxMi44djE2Ni40em0wLTI1NmMwIDYuNC02LjQgMTIuOC0xMi44IDEyLjhINzYuOGMtNi40IDAtMTIuOC02LjQtMTIuOC0xMi44Vjc2LjhDNjQgNzAuNCA3MC40IDY0IDc2LjggNjRoMjk0LjRjNi40IDAgMTIuOCA2LjQgMTIuOCAxMi44djEwMi40eiIgY2xhc3M9IiI+PC9wYXRoPjwvc3ZnPg==') no-repeat center;
        background-size: 45%;
    }

    // add
    .node-type-function.node-operation-add > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMzQxLjQgMzQxLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM0MS40IDM0MS40OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBvbHlnb24gcG9pbnRzPSIxOTIsMTQ5LjQgMTkyLDAgMTQ5LjQsMCAxNDkuNCwxNDkuNCAwLDE0OS40IDAsMTkyIDE0OS40LDE5MiAxNDkuNCwzNDEuNCAxOTIsMzQxLjQgMTkyLDE5MiAzNDEuNCwxOTIgDQoJCQkzNDEuNCwxNDkuNCAJCSIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
        background-size: 45%;
        background-repeat: no-repeat;
        background-color: white;
    }
    // subtract
    .node-type-function.node-operation-subtract > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cmVjdCB5PSIyMjIuNjA5IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjY2Ljc4MyIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
        background-size: 45%;
        background-repeat: no-repeat;
        background-color: white;
    }
    // divide
    .node-type-function.node-operation-divide > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDIgNDIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQyIDQyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8cmVjdCB5PSIxOSIgd2lkdGg9IjQyIiBoZWlnaHQ9IjQiLz4NCgk8cGF0aCBkPSJNMjEsMTNjMi43NTcsMCw1LTIuMjQzLDUtNXMtMi4yNDMtNS01LTVzLTUsMi4yNDMtNSw1UzE4LjI0MywxMywyMSwxM3oiLz4NCgk8cGF0aCBkPSJNMjEsMjljLTIuNzU3LDAtNSwyLjI0My01LDVzMi4yNDMsNSw1LDVzNS0yLjI0Myw1LTVTMjMuNzU3LDI5LDIxLDI5eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=');
        background-size: 45%;
        background-repeat: no-repeat;
        background-color: white;
    }
    // multiply
    .node-type-function.node-operation-multiply > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMzIuNTI2IDMyLjUyNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIuNTI2IDMyLjUyNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBvbHlnb24gcG9pbnRzPSIzMi41MjYsMi44MjggMjkuNjk4LDAgMTYuMjYzLDEzLjQzNSAyLjgyOCwwIDAsMi44MjggMTMuNDM1LDE2LjI2MyAwLDI5LjY5OCAyLjgyOCwzMi41MjYgMTYuMjYzLDE5LjA5MSANCgkyOS42OTgsMzIuNTI2IDMyLjUyNiwyOS42OTggMTkuMDkxLDE2LjI2MyAiLz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
        background-size: 42%;
        background-repeat: no-repeat;
        background-color: white;
    }
    // maximum
    .node-type-function.node-operation-maximum > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMzQxLjMzMyAzNDEuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNDEuMzMzIDM0MS4zMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBwb2ludHM9IjE3MC42NjcsMCAwLDE3MC42NjcgMzAuMTg3LDIwMC44NTMgMTQ5LjMzMyw4MS43MDcgMTQ5LjMzMywzNDEuMzMzIDE5MiwzNDEuMzMzIDE5Miw4MS43MDcgMzExLjE0NywyMDAuODUzIA0KCQkJMzQxLjMzMywxNzAuNjY3IAkJIi8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=');
        background-size: 42%;
        background-repeat: no-repeat;
        background-color: white;
    }
    // minimum
    .node-type-function.node-operation-minimum > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMzQxLjMzMyAzNDEuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNDEuMzMzIDM0MS4zMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBwb2ludHM9IjMxMS4yNTMsMTQwLjU4NyAxOTIsMjU5LjYyNyAxOTIsMCAxNDkuMzMzLDAgMTQ5LjMzMywyNTkuNjI3IDMwLjI5MywxNDAuMzczIDAsMTcwLjY2NyAxNzAuNjY3LDM0MS4zMzMgDQoJCQkzNDEuMzMzLDE3MC42NjcgCQkiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==');
        background-size: 42%;
        background-repeat: no-repeat;
        background-color: white;
    }
    // first
    .node-type-function.node-operation-first > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjY0Ljc0NyAyNjQuNzQ3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNjQuNzQ3IDI2NC43NDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8Zz4NCgkJCTxwb2x5Z29uIHBvaW50cz0iMjY0Ljc0NywzNC40NTMgMjM0LjY2Nyw0LjM3MyAxMDYuNjY3LDEzMi4zNzMgMjM0LjY2NywyNjAuMzczIDI2NC43NDcsMjMwLjI5MyAxNjYuODI3LDEzMi4zNzMgCQkJIi8+DQoJCQk8cmVjdCB4PSIwIiB5PSI0LjM3MyIgd2lkdGg9IjQyLjY2NyIgaGVpZ2h0PSIyNTYiLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
        background-size: 35%;
        background-repeat: no-repeat;
        background-color: white;
    }
    //last
    .node-type-function.node-operation-last > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjY0Ljc0NyAyNjQuNzQ3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNjQuNzQ3IDI2NC43NDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8Zz4NCgkJCTxwb2x5Z29uIHBvaW50cz0iMCwzNC40NTMgOTcuOTIsMTMyLjM3MyAwLDIzMC4yOTMgMzAuMDgsMjYwLjM3MyAxNTguMDgsMTMyLjM3MyAzMC4wOCw0LjM3MyAJCQkiLz4NCgkJCTxyZWN0IHg9IjIyMi4wOCIgeT0iNC4zNzMiIHdpZHRoPSI0Mi42NjciIGhlaWdodD0iMjU2Ii8+DQoJCTwvZz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==');
        background-size: 35%;
        background-repeat: no-repeat;
        background-color: white;
    }
    //average 
    .node-type-function.node-operation-average > .rst__moveHandle {
        box-shadow: none;
        height: 45px;
        border: 1px solid $black2-color ;
        border-radius: 0px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABbUlEQVRoge3Yy0rDQBSA4d9S7EUXUhX6XL5CxSdwqfhQCl6qC7eFYt3o00hcJAdqmAlJZuZkhPNDds2Z+ZiWhIJlWZZlWRbAAriKOG8FnEec16oTYAMUwG2EedfVrG9gGWFeq/YRct0EzBOEXF8oYBbAtrZwAbwB4x7zxtW99XmfwFmE/TpznUQBvAPHAXNnwNoxNwkmFUJSwTQhjmItQmKMFkLyYXYEYHyIl2rBVE2AB8e6vTBDIaQomKERUhDGh3hGFyFNgHvHfnbAqe+m3BCSD/OBA+N7Yj8BU539Njal3Et9f1vKvQP5nkS9QxpOZjTgxrp20OZDuZ9Kp99JrphOCCk3jO9Z0oiQcsEEIaShMTPKN4kghDQUxvcG3AshaWOSICQtTFKElBozRwEhpcLMgVfH3CQIyfdy+Ui/v4NGuBF/XgZT5TqZu4B5l8APSidRbx8TgpBWlBhVhBT7T+wLFL5OlmVZlmX9h34B5akehjqWVL8AAAAASUVORK5CYII=');
        background-size: 50%;
        background-repeat: no-repeat;
        background-color: white;
    }

    // entity
    .rst__rowContentsDragDisabled {    
        //box-shadow: 3px 2px $black5-color !important;
        box-shadow: none !important;
        border-radius: 0px !important;
        border: 1px solid $black2-color !important ;   
    }

    //botão collaps e expand
    /*
    button.rst__expandButton:first-of-type , button.rst__collapseButton:first {
    background-position-x: 10px;
    } */

    button.rst__expandButton {
        background-color: white;
        background-size: 70%;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMzQxLjQgMzQxLjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM0MS40IDM0MS40OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPHBvbHlnb24gcG9pbnRzPSIxOTIsMTQ5LjQgMTkyLDAgMTQ5LjQsMCAxNDkuNCwxNDkuNCAwLDE0OS40IDAsMTkyIDE0OS40LDE5MiAxNDkuNCwzNDEuNCAxOTIsMzQxLjQgMTkyLDE5MiAzNDEuNCwxOTIgDQoJCQkzNDEuNCwxNDkuNCAJCSIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
    }
    button.rst__collapseButton {
        background-color: white;
        background-size: 70%;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cmVjdCB5PSIyMjIuNjA5IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjY2Ljc4MyIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
    }
    .rst__collapseButton:hover:not(:active), .rst__expandButton:hover:not(:active) {
        background-size: 65% !important;
        height: 18px;
        width: 18px
    }

    .tree-select {
        max-height: 40px;
        width: 200px;
    }
}
