.lumitiot-productionreport {

  .clickable {
    cursor: pointer;
  }

  // header container
  .production-order-header-container {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: 5px;
  

    //   display: flex;
    //   flex-direction: row;
    //   align-items: flex-start;
    //   flex-wrap: wrap;
       background-color: white;
    //   border-radius: 5px;

    h1  {
      margin-top: 6px;
    }
  }

  .production-order-header-right {
    line-height: 30px;
    text-align: right;
    flex: 1;
    font-size: 16px;

  }

  .production-order-header-left {
  
  }
  //navigations
   .nav-tabs {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
    border-radius: 5px !important;
  }
 
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    padding: 10px;
    background-color: white;
    color: black;
    border-radius: 5px !important;
    border-color: transparent;
    margin-right: 10px;
  }

  .nav-tabs .nav-link.active {
    background-color: $primary-color !important; // primary-color-hover
    border-radius: 5px 5px 5px 5px  !important;
  }
  .nav-tabs .nav-link.active:nth-child(2) {

  }
  // .hide-print {
  //   margin-top: 20px !important;
  // }

  // .highcharts-root {
  //   background-color: red !important;
  //   border-radius: 5px;
  //   margin-top: -10px;
  // }

  .tab-content  {

    h4 {
      margin-top: 20px;
      margin-bottom: -10px;
      padding: 0px;
    }
  }

  .highcharts-container {
    border-radius: 5px;
    //margin-top: -10px;
  }

  .lote-card {
    margin-bottom: 5px !important;
  }

  .lote-card-header {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lote-card-header-summary {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;
  }

  .lote-card-header-summary-item {
    flex: initial;
    height: 34px;
    line-height: 20px;
    margin-left: 20px;
  }

  .lote-card-header-summary-item:nth-child(1) {
    line-height: 34px;
    margin-right: 20px;
    margin-left: 0px;
  }

  .lote-card-header-summary-item:last-child {
    flex: auto;
    text-align: right;
    line-height: 34px;
  }

  .accordion .card-header .triangle {
    margin-left: 8px;
  }

  .card.production-info-card {
    transition: all 0.1s ease-in;
  }

  .card.production-info-card .triangle {
    transition: all 0.1s ease-in;
    background-image: none;
    background-image: initial;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent #000 transparent transparent;
    height: 5px;
    float: right;
    margin-top: 4px;
  }

  .card.production-info-card.open .triangle {
    transform: rotate(-90deg);
    margin-top: 7px;
    margin-left: 5px;
  }

  .header-cards-info {
    margin-top: -20px;
  }

  .production-info-card-small {
    background-color: $primary-light-2 !important;
    margin: 0px;
    padding: 0px;
  }

  .lote-lanes-header-container {
    margin-left: 0px;
    width: 100%;
  }

  .lote-lanes-header-container .lote-lanes-header-card {
    line-height: 34px;
    margin-right: 20px;
  }

  .lote-lanes-header-container .lote-lanes-header-card-info {
    padding: 0px 15px;
    background-color: #f5f5f5;
  }

  .lote-lanes-header-container .lote-lanes-header-card-info strong {
    margin-right: 10px;
  }

  .lote-lanes-header-container .lote-lanes-header-card-button {
    margin-top: -2px;
  }

  .btn-sm {
    padding: 6px 11px;
  }

  .lote-card-header-summary-item-icon {
    padding: 6px; 
    border: 1px solid #ebedef;
    background-color: #ebedef;
    min-width: 65px;
  }

  .lote-card-header-summary-item-icon.success {
    background-color: #68DEC6;
  }

  .lote-card-header-summary-item-icon.alert {
    background-color: #FFE495;
  }

  .lote-card-header-summary-item-icon.warning {
    background-color: #FF9357;
  }

  .lote-card-header-summary-item-icon.danger {
    background-color: #FF7272;
  }

  .productionreport-component {
    padding: 0px;
    margin: 0px;
  }

  .nonconformant-entry {
    display: inline-block;
    padding: 0 5px;
    margin-right: 5px;
    background-color: #ebedef;
    line-height: 25px;
  }

  .header-cards {
    .col {
      padding-right: 10px;
      padding-left: 0px;
      margin-bottom: 10px;
    }

    .col:first-child {
      padding-left: 15px;
    }

    .col:last-child {
      padding-right: 15px;
    }
  }

  .header-cards-info {
    .col {
      padding-right: 10px;
      padding-left: 0px;
      margin-bottom: 10px;
    }

    .col:first-child {
      padding-left: 15px;
    }

    .col:last-child {
      padding-right: 15px;
    }
  }

  .production-info-card {
    .card-body {
      padding: 10px 15px !important;
    }
  }
  
  //table 
  .table tr:last-child {
    border-radius: 5px !important;
  }

  .table thead th:first-child {
   border-radius: 5px 0px 0px 0px !important;
  }
  
  .table thead th:last-child {
   border-radius: 0px 5px 0px 0px !important;
  }

  @media (max-width: 1366px) {
    
    .header-cards {
      .col {
        padding-right: 10px;
        padding-left: 0px;
      }

      .col:first-child {
        padding-left: 15px;
      }

      .col:last-child {
        padding-right: 15px;
      }
    }

    .production-info-card {

      .card-body {
        padding: 10px 10px !important;
      }

      .text-uppercase {
        font-size: 10px !important;
        line-height: 14px !important;
      }
      
      .text-value {
        font-size: 12px !important;
        line-height: 16px !important;
      }
    }
  }
}

.show-print {
  display: none;
}

@media print {
  .hide-print {
    display: none;
  }
  
  .show-print {
    display: block;
  }
}
