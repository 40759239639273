//   Sidebar
.c-sidebar-header-project:after {
  content: '';
  background-image: url('/images/lumitiologo_inv.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 57px;
  top: 13px;
  font-size: 16px;
  background-color: $primary-color;
  width: 100px;
  height: 30px;
}

.layer-close {
//  width: calc(100vw - 215px);
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
//  z-index: -10;
  margin-left: 215px;
}

.iconSidebarMenu {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMzIiIGhlaWdodD0iMzIiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTIxLjUsMzcuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsODAuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsMTIzLjYyNXYxMC43NWgxMjl2LTEwLjc1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  background-size: contain;
  width: 25px;
  height: 24px;
  margin-left: 1px;
  margin-top: -1px;
  z-index: 10;
}

.iconSidebar {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.882' height='25.905' viewBox='0 0 13.882 25.905'%3E%3Cpath id='Icon_metro-chevron-thin-left' data-name='Icon metro-chevron-thin-left' d='M24,28.408c0-.012-1.488,1.536-1.495,1.51l-12.077-12.2a1.074,1.074,0,0,1,0-1.51L22.5,4.013c.038,0,1.508,1.5,1.495,1.51L12.982,16.967,24,28.408Z' transform='translate(-10.115 -4.013)'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  background-size: contain;
  width: 19px;
  height: 19px;
  margin-left: 3px;
  margin-top: 2px;
}

.c-sidebar {
  color: #000;
  background: #fff;
  width: 215px;
}

.c-sidebar .c-sidebar-brand {
  color: #000;
  height: 50px !important;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #000;
  font-weight: bold !important; 
  padding-left: 4px;
}

.c-sidebar .c-sidebar-nav-item.c-active {
  color: #000;
  background: $grey6-color;
  font-weight: bold !important; 
}

.c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-active {
  color: #000;
  background: $grey6-color;
  font-weight: bold !important;
}

.c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-active {
  color: #000;
  background: $grey6-color;
  font-weight: bold !important; 
}

.c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-active {
  color: #000;
  background: $grey6-color;
  font-weight: bold !important; 
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #000;
  font-size: 12px;
  font-family: "Arial";
}
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #000;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  color: #000;
  background: $grey7-color;
}

.c-sidebar .c-sidebar-nav-dropdown .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle {
  color: #000;
  font-size: 12px;
  font-family: "Arial";
}

.c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle {
  background: $grey7-color;
}

.c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-items {
  background: $grey7-color;
  //padding-left: 15px;
}

.c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle {
  background: $grey7-color;
}

.c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle {
  background: $grey7-color;
}

.c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-items {
  color: #000;
  background: $grey7-color;
}

.c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-items {
  color: #000;
  background: $grey7-color;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: initial;
  border-style: solid;
  border-width: 5px 5px 5px 0;
  border-color: transparent #000 transparent transparent;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle::after {
  background-image: initial;
  border-width: 5px 5px 5px 0;
  padding-left: 5px;
}

.c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after {
  border-width: 5px 5px 5px 0;
  background-image: initial;
}

.c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::before,
.c-sidebar :hover.c-sidebar-nav-dropdown-toggle::before {
  border-width: 5px 5px 5px 0;
  background-image: initial;
}

.c-sidebar-nav-icon {
  flex: 0 0 56px;
  height: 19px;
  font-size: initial;
  text-align: center;
  transition: 0.3s;
  fill: currentColor;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 100px; 
  flex: initial;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
  padding-left: 63px;
  font-size: 12px;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
  padding-left: 70px;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
  padding-left: 70px;
}

html:not([dir="rtl"]) .c-sidebar-nav-icon:first-child {
  margin-right: 3px;
}

@media (hover: hover) {
  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
      color: #000;
      // background: $white2-color;
      background: $grey5-color; 
  }

  .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #000;
    //background: $white2-color;
  }

  .c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle:hover {
      color: #000;
      background: $grey5-color;
  }
  
  .c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle:hover {
      color: #000;
      background: $grey5-color;
  }
  
  .c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle:hover {
      color: #000;
      background: $grey5-color;
  }

  .c-sidebar-minimized .c-sidebar-nav-link:hover .c-sidebar-brand-full,
  .c-sidebar-minimized .c-sidebar-nav-dropdown:hover .c-sidebar-brand-full {
    display: block;
  }
}

.c-sidebar-header-brand .c-sidebar-nav-link,
.c-sidebar-header-brand .c-sidebar-nav-link:hover {
  color: #000;
  background-color: $primary-color;
   padding: 13px 15px 13px 15px;
   height: 51px;
}

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  padding: 14px;
}

.c-sidebar-minimized {
  overflow: hidden;
}

.c-sidebar-minimized .c-sidebar-nav {
  width: calc(100% + 25px);
  overflow-x: hidden;
  overflow-y: auto;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #000;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  color: #000;
  background: $grey7-color;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
  color: #000;
  background: $grey7-color;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  color: #000;
  background: $grey7-color;
 
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
  color: #000;
  background: $grey7-color;
}

.c-sidebar-minimized .c-sidebar-header-brand .c-sidebar-nav-link:hover,
.c-sidebar-minimized .c-sidebar-header-brand .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar-minimized .c-sidebar-header-brand .c-sidebar-nav-dropdown-toggle:hover  {
  width: auto;
}

.c-sidebar-header-project {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}

.c-sidebar-nav-item .c-icon {
  margin-right: 15px;
}

.c-sidebar-nav-dropdown-items:after {
  padding-left: 0px;
  font-weight: normal;
}

.c-sidebar-minimized .c-sidebar-nav-item .c-icon {
  margin-right: 0px;
}

.c-sidebar-prodheader {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  height: auto !important;
  padding-bottom: 0px;
}

.c-sidebar-minimized .c-sidebar-prodheader {
  visibility: visible;
}

.iconSidebarMenu {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAAVElEQVRoge3W0Q2AMAxDQRcxOExOhyBSoupuAvvvJQA0WEme7hEVVpKve0SFq3tAFUemuZO83SMAgCIyfppjotGRaWQ8AJxExk9zTDQ6Mo2MB+CPDXJLBiVsKHgMAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  background-size: contain;
  width: 19px;
  height: 19px;
  margin-left: 3px;
  margin-top: -1px;
  z-index: 10;
}

@media (max-width: 992px) {
  .c-sidebar {
    box-shadow: 4px 0px 15px grey;
  }

  html:not([dir="rtl"]) .c-sidebar.c-sidebar-fixed:not(.c-sidebar-right) {
    margin-left: 0px;
  }
  
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-fixed.c-sidebar-minimized:not(.c-sidebar-right) {
    margin-left: -256px;
  }
}

@media (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper, html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper {
    margin-left: 50px;
  }
  
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper, html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 215px;
  }

  .c-sidebar-minimized.c-sidebar-fixed {
    width: 55px;
  }
  .c-sidebar-minimized .c-sidebar-nav {
    padding-bottom: 50px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    width: initial;
    overflow: initial;
  }

  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover > .c-sidebar-nav-dropdown-items {
    position: relative;
    display: none;
  }

  .c-sidebar-minimized .c-sidebar-nav-link:hover,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover {
    width: 235px;
  }

  .c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
    width: 174px;
  }

  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items {
    max-height: 50vh;
  }
}

.c-sidebar-nav-item.c-active {
  position: relative;
}

.c-sidebar-nav-item.c-active::after {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 5px;
  background-color: $primary-color !important;
}

.c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  position: relative;
}

.c-sidebar-nav-dropdown.c-active-link .c-sidebar-nav-dropdown-toggle::before {
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 5px;
  background-color: $primary-color;
}

.d-md-block {
  background-color: $primary-color;
}

.c-header .c-header-toggler-icon {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAAVElEQVRoge3W0Q2AMAxDQRcxOExOhyBSoupuAvvvJQA0WEme7hEVVpKve0SFq3tAFUemuZO83SMAgCIyfppjotGRaWQ8AJxExk9zTDQ6Mo2MB+CPDXJLBiVsKHgMAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  background-size: contain;
  width: 19px;
  height: 19px;
  margin-left: 3px;
  margin-top: 5px;
  z-index: 10;
}

