// visionreport
.dashboard-widget.vision-report {

   .nav-tabs .nav-link {
    padding: 0px 16px !important;
  }

  table th, .table th, .table thead th {
    background-color: white;
    border-bottom: 1px solid $lines-input-color;
    font-size: 12px !important;
  }

  .table tr td {
    padding-left: 10px;
  }

  table td {
    border-bottom: 1px solid $lines-input-color;
  }

  table {
    border-radius: 5px !important;
  }

  .table tr td .btn {
    color: $primary-color;
    padding: 7px;
    line-height: 15px;
    text-align: left;
    width: auto;
  }
  
  .c-switch  {
    top: 10px !important;
    width: 30px !important;
    height: 15px !important;
  }

  .c-switch[class*="-3d"] .c-switch-slider::before {
    width: 20px;
    height: 20px;
    top: -4px;
  }


}