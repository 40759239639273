// ag grid

.newtable-component {
  // aknowledge btn
  .btn-primary {
    background-color: transparent;
    border: 2px solid $primary-color;
    font-size: 11px;
    color: $primary-color !important;
    padding: 3px 5px;
    margin-bottom: 2px;

    .c-icon {
      display: none;
    }
  }

  .btn-primary:hover {
    background-color: $primary-color;
    border: 2px solid $primary-color;
    color: black !important;
  }

  .btn-outline-link {
    border-radius: 5px !important;
    border-color: transparent!important;
    color: black!important;
    min-width: 35px !important;
    font-weight: 100 !important;
    text-transform: none;
    background-color: $grey-color !important;
  }

  .btn-outline-link.active {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: black !important;
  }

  .btn-outline-link:hover {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: Black !important;
  }


}
.ag-header-container, .ag-header-viewport, .rawmaterialmap-component .ag-theme-alpine .ag-header  {
  min-height: 40px;
  border-radius: 5px 5px 0px 0px; 
}

.ag-react-container {
  margin-left: 20px;
}

.table-component {
  .card-body {
    padding: 0px;
  }
}

.newtable-component .card-body {
    //padding: 15px; 
    padding: 0px;
  }

  .paddingTable .card-body {
    padding: 5px 15px 15px 15px;
  }

  .ag-theme-alpine .ag-root-wrapper {
      border: 0px;
  }
  .ag-theme-alpine .ag-ltr .ag-column-drop-horizontal {
    background-color: $grey-color!important;

  }
  .newtable-component .ag-theme-alpine .ag-header {
    background-color: transparent !important;
    min-height: 40px !important;
    height: 40px !important;
    border: 0px;
  }

  .ag-cell-label-container .ag-header-cell-sorted-none {
    min-height: 40px !important;
  }

  //cor do header
  .ag-header-viewport {
      background-color: $primary-color;
  }
  .ag-ltr .ag-header-cell-resize {
    min-height: 40px !important;
    height: 40px !important;
  }
  .ag-header-group-cell-label, .ag-header-cell-label {
    min-height: 40px !important;
    height: 40px !important;
    color: black !important;
    text-transform: uppercase;
    font-size: 11px !important;
    letter-spacing: 1px;
    align-items: center;
  }
  
  .ag-theme-alpine .ag-icon-menu::before {
    color: black !important;
    min-height: 40px !important;
    height: 40px !important;
    align-items: center !important;
  }
  
  // header filtros
  .ag-theme-alpine .ag-ltr .ag-column-drop-horizontal {
    background-color: black;
    border: 0px;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  // side buttons 
  .ag-theme-alpine .ag-side-buttons, .ag-theme-alpine .ag-ltr .ag-side-bar-right {
    background-color: #EEEEEE;
    border-radius: 0px 5px 5px 0px;
    border-color: $lines-input-color !important;
  }
  
  //header ag grid
  .ag-header-viewport {
    font-size: 12px;
    letter-spacing: 1px ;
    border-radius: 5px 5px 0px 0px;
    padding-left: 20px;
  }
  
  .ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
    padding-left: 5px ;
  }
  
  .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    //color: #223A44;
    font-size: 12px;
    padding-left: 20px ;
    padding-right: 20px ;
  }
  
  .newtable-component .ag-theme-alpine .ag-root-wrapper {
    border-radius: 5px ;
    border: 0px ;
    background-color: $background;
  }
  
  .newtable-component .ag-theme-alpine .ag-row:hover {
    border: 0px !important;
    background-color: $primary-light-5;
  }
  
  .newtable-component .ag-theme-alpine .ag-row :focus {
    background-color: $primary-light-5;
    border: 0px !important;
  }
  
  .newtable-component .ag-theme-alpine .ag-row :active {
    background-color: $primary-light-5;
    border: 0px ;
  }
  
  .newtable-component .ag-theme-alpine .ag-row ::before {
    background-color: $primary-light-5;
    border: 0px ;
  }
  
  // linhas
  .ag-center-cols-container .ag-row {
    background-color: $background ;
    border-bottom: 0px ;
  }
  
  .ag-center-cols-container .ag-row:hover {
    background-color: $primary-light-5;
    border-bottom: 0px;
  }
  
  // filtros pop up ag grid
  .ag-theme-alpine .ag-popup-child:not(.ag-tooltip-custom) {
   border-color: $primary-light-5;
   border-radius: 5px;
   background-color: $background;
  }
  
  .ag-theme-alpine .ag-tab-selected {
   // border-bottom: 0px solid #5797A0 !important;
  }

  // ag grid inside ag grid
  .ag-header .ag-focus-managed .ag-pivot-off .ag-header-viewport {
    
  }
  