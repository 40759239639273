

.dashboard-widget .card {
    border-radius: 5px;
}

.modelform-component {


    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item:nth-last-child(1) .nav-link, .nav-tabs .nav-link {
        font-size: 12px;
        padding: 0px 16px;
    }

    .ace_editor * {
        font-family : monospace !important;
        font-size: 16px !important;
        direction:ltr !important;
        text-align:left !important;
    }

    .nav-tabs {
        margin-bottom: 0px;
        padding-bottom: 0px;
    
        .nav-item {
            border-right: 1px solid #000000;
        }

        .nav-item:last-child {
            border-right: 0px;
        }
    }

    .tab-pane .card {
        background-color: $white3-color;
    
    }

    .card-footer .btn:nth-child(1) {
        padding: 12px 17px 12px 17px;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    
    .btn.btn-light.btn-sm {
        background-color: $cancel-color;
        padding: 12px 17px 12px 17px;
        margin-bottom: 10px;
    }

    .btn.btn-light.btn-sm:hover { 
        background-color: $cancel-hover;
        color: black;
    }
    
    label {
        display: block;
    }

    .custom-select {
        display: block;
        border-radius: 5px;
    }

    .basic-multi-select .select__control, .basic-multi-select .select__indicators {
        border-radius: 5px !important;
    }

    .tab-content tr {
        background-color: #ffffff;
    }

    table thead tr:nth-child(2) .form-control {
        margin-top: 10px;
    }

    .alarm-container {
        padding-bottom: 0px;
    }

    .alarm-conditions {
        margin-left: -25px;
        margin-right: -25px;
        padding-top: 25px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .alarm-conditions:first-child {
        margin-top: 15px;
    }
   
    .alarm-conditions:nth-child(odd) {
        background-color: #fff;
    }
   
    .alarm-conditions:nth-child(even) {
        background-color: $grey7-color;
    }
   
   

    .form-group.disabled {
    }

    .form-group {
      .btn.btn-outline-link.btn-lg,
      .btn.btn-outline-success.btn-lg,
      .btn.btn-outline-warning.btn-lg,
      .btn.btn-outline-danger.btn-lg {
          width: 40px;
          height: 40px;
          margin-top: 17px;
          vertical-align: middle;

          .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
              margin-top: 0px!important;
              margin-left: 0px !important;
          }
      }

      .btn.btn-outline-link.btn-lg {
          border: 1px solid black;
      }

      .btn.btn-outline-link.btn-lg:hover {
          color: black;
      }
      
      .btn.btn-outline-link.btn-lg.disabled,.btn.btn-outline-link.btn-lg:disabled{
          color: $black2-color;
          border: 1px solid $black2-color;
          cursor: auto;
      }
    }

    .form-control {
        padding-left: 20px !important;
        border-radius: 5px;
    }

    .c-icon.iconButton{
        padding: 0px !important;
        width: 21px !important; 
        height: 21px !important;
        margin-top: 0px !important;
        margin-right: 5px;
    }
    

    // calcs

  

}

.modelform-component .ace_editor  {
    border-radius: 5px 0px 0px 5px;
}

.modelform-component .ace_content {
    border-radius: 0px 5px 0px 0px;
}

/*
.formGroupAlign {
    width: 49%;
    display: inline-block;
    margin-bottom: 30px;

    .custom-select {
        height: 50px;
    }
}

.formGroupAlign2 {
    width: 49%;
    display: inline-block;
    float: right;
    margin-bottom: 30px;

    .custom-select {
        height: 50px;
    }
}
*/

.dashboard-widget .row {
    button.btn.btn-primary.btn-sm {
        padding: 12px 17px 12px 17px;
    }
}

.prodtrack-modelform {
  .actions-wrapper {
    .btn {
      padding: 0px;
    }
  }
  
  .workinstruction-container {
    .form-control {
      height: auto;
      min-height: 40px;
     
    }
    
    textarea {
        resize: none;
    }
    
    .nav-tabs {
        /*margin-top: 25px;*/
      
    }

    .nav .nav-tabs {
        padding: 0px 16px !important;
    }

    .nav-tabs .nav-item {
        border-right: 0px;
        margin-bottom: 0px;

    }

    .nav-tabs .nav-link {
        background-color: $primary-light-4 !important;
        border-radius: 5px 5px 0px 0px !important;
        color: #000;
        height: 100%;
        border: 0px
    }


    .nav-tabs .nav-item {
        margin-left: 10px;
    }



    .nav-tabs .nav-item:first-child {
        margin-left: 0px;
    }

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        background-color: $primary-light-4 ;
    }

    .tab-pane .card {
        background-color: $primary-light-4;
    }

    .remove-step {
        margin-left: 15px;
    }

    .add-step {
        width: 51px;
        height: 41px;
        background-color: $primary-light-1 !important;
        border-radius: 5px 5px 0px 0px !important;
    }

    .tab-content {
        margin-left: -25px;
        margin-right: -25px;
    }

    .upload-area {
        text-align: center;
        padding: 50px 40px;
        background-color: #fff;
        border: 1px dashed $grey2-color;
        cursor: pointer;
    }

    .file-input {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
  }

  .DayPicker-Weekday {
    height: 40px;
  }
}
.prodtrack-modelform .workinstruction-container .tab-pane .card {
    background-color:  $primary-light-4 !important;

    .actions-entry {
        background-color:  white !important;
        border-radius: 5px;
        border-color: $input-boxes;
    }
}

// Breakpoint 540px
@media (max-width: 540px) {
    .modelform-component {

        .card {
            padding: 25px;
        }

        .card-footer {
            padding: 0px;
        }

        .card-footer .btn:nth-child(1) {
            margin: 10px;
            padding: 12px 17px 12px 17px;
            margin-left: 0px !important;
            margin-bottom: 0px !important;
        }

        .btn.btn-light.btn-sm {
            padding: 12px 17px 12px 17px;
            margin-bottom: 0px !important;
            margin: 10px;
        }
        
    }
}