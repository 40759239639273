
.fc .fc-timegrid-body {
  min-height: 100% !important;
}

.fc .fc-timegrid-slots {
  min-height: 100% !important;
}

.fc .fc-timegrid-slots table {
  min-height: 100% !important;
}

// clear filtros
.btn-success {
  background-color: $grey-color;
  border: 0px;
  color:  black;
  font-size: 12px !important;
  font-weight: 100;
  text-transform: none;
  min-height: 35px;
}

.btn-success:hover { 
  background-color:  $primary-light-1;
}

// Fullcalendar
.calendar-component {
  background-color: #fff;
  border-radius: 5px;

  .fc-timegrid-event, .fc-timegrid-more-link {
    font-size: 11px;
  }
  
  .fc .fc-scrollgrid-section-body table, .fc .fc-scrollgrid-section-footer table {
    border-bottom-style: initial;
  }

  .context-menu {
    display: none;
    position: absolute;
    background-color: #FEFEFE;
    border: 1px solid #CCC;
    z-index: 99999;
    list-style: none;
  }

  .context-menu.open {
    display: block;
  }

  .expand-backlog {
    position: absolute;
    right: -10px;
    top: 50%;
  }

  .basic-multi-select {
    z-index: 99999;
  }
  
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0px;
    padding: 15px;
  }
  
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0px;
    padding: 0px 0px 25px 0px;
    background-color: #ffffff;
  }

  .fc-toolbar-title {
    text-transform: uppercase !important;
    text-align: center;
  }

  //titulo calendar
  .fc .fc-toolbar-title {
    font-size: 14px;
    font-weight: bold;
  }

  // all buttons
  .fc .fc-button {
    text-transform: capitalize;
    background-color: $grey-color !important;
    color: black !important;
    margin-right: 15px;
    line-height: 22px;
    min-width: 35px;
    border-radius: 5px !important;
    font-size: 12px !important;
    min-height: 35px;
    border: 0px !important;
    padding: 0px 5px !important;
  }

  .fc .fc-button:active {
    background-color: $primary-color !important;
    padding: 0px 5px !important;
  }

  // .fc .fc-button-primary:active, .fc .fc-button-primary.fc-button-active,
  //  .fc .calendar-btn.active ,    .fc .fc-button {    
  //   background-color: $primary-color;
  //   border: 0px !important;
  //   border-radius: 4px;

  // }
  
  // .fc .fc-button-primary:hover, .fc .calendar-btn:hover{    
  //   background-color: $primary-color;
  //   border-radius: 4px;
  //   border: 0px !important;
  // }

  // .fc .fc-button:disabled {
  //   opacity: 0.4;
  // }

  // table.fc-col-header th:first-child {
  //   border-radius: 5px 0px 0px 0px !important;
  //   background-color: $primary-light-1 !important;
  //   border: 0px !important;
  // }

  .fc .fc-button {
    background-color: white;
    //border: 1px solid $primary-light-2 !important;
  }

  .fc .fc-button:active {
    background-color: transparent!important;
    //border: 1px solid $primary-light-1 !important;
  }
 

   // botoes setinhas icon
   .fc .fc-button .fc-icon {
    align-items: center;
    line-height: 15px;
  }

  // button Timeline Week e fit
  .fc .fc-button-group .fc-button:last-child {
    margin-right: 0px;
    background-color: $grey-color !important;
  }
 

  // select
  .filter-toolbar .filter-toolbar-field-button .filter-toolbar-field-label {
    margin-top: 1px;
    border-bottom: $input-boxes !important;
    height: 35px !important;
  }
  .filter-toolbar .filter-toolbar-field-icon {
    height: 36px !important;
  }

  // select icons
  .filter-toolbar-field-icon {
    .c-icon {
      width: 14px !important;
      height: 14px;
      margin-left: 1px;
    }    
  }

  // pop up search and sort
  .filter-toolbar .filter-toolbar-field-dropdown-sort-container {
    border-bottom: 1px solid #b5b5b5;
  }
  
  .filter-toolbar .filter-toolbar-field-dropdown {
    border-radius: 5px;
    min-width: 220px;
 
    .form-control {
      //margin-top: -15px;
      font-size: 12px;
      height: 35px;
      border-radius: 5px;
    }
  }


  .filter-toolbar .filter-toolbar-field-dropdown-filter-container {
    padding: 10px 14px;
  }
  
   .filter-toolbar .filter-toolbar-field.has-open .filter-toolbar-field-icon {
    border-left: 1px solid $primary-light-1;

   }

   // date
   .filter-toolbar .date-container .filter-toolbar-field-dropdown-filter-container .form-control {
    min-width: 190px;
    color: black;
   }

  
  // table
  .fc-col-header {
    //border-radius: 5px 5px 0px 0px;
 
  }
  .fc .fc-datagrid-header .fc-datagrid-cell-frame {
    background-color: $primary-light-1 ;
    border-radius: 5px 0px 0px 5px !important;
  }

  .fc .fc-timeline-slot-frame {
    background-color: $primary-light-1;
    font-weight: bold !important;
  }

  // barra vertical tabela
  .fc-timegrid-axis {
    background-color: $grey-color; 
  }

  //header color 
  table.fc-col-header th, .table.fc-col-header th, .table.fc-col-header thead th {
    background-color: $primary-light-1;
    border: 1px solid white;
  }

  table.fc-col-header th:first-child {
    border-radius: 5px 0px 0px 5px !important;
    background-color: $primary-light-1;
  }



  // production cards           COOOREEEESSS DOS EVENTOS

  a.fc-v-event,
  a.fc-h-event {
    background-color: $primary-light-3;
    border-radius: 3px;
    //border: 2px solid $primary-light-2;
    color: black;
  }

  a.fc-v-event:hover,
  a.fc-h-event:hover {
    //background-color:  $primary-light-3 !important;
  }

  .fc-event-main {
    padding-top: 0px;
    margin-top: -7px;
  }

  a.fc-v-event:hover,
  a.fc-h-event:hover {
    background-color:  $primary-light-3;
  }

  .fc-v-event .fc-event-main {
    color: black;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: bold;
  }

  .fc .fc-scrollgrid {
    border: 0px;
  }
  .fc-timegrid-slots tr {
    height: 10px;
    position: relative;
  }
  .fc-timegrid-slots tr td {
    font-size: 9px !important;
  }
  .fc .fc-timegrid-slot {
    height: auto;
  }

  a.workorder-event {
    background-color: $grey2-color;
    border: 2px solid $grey2-color;
  }

  a.productionorder-event {
    background-color: $blue2-color;
    border: 2px solid $blue2-color;
  }

  a.maintenanceorder-event {
    background-color: $orange1-color;
    border: 2px solid $orange1-color;
  }

  a.qualityorder-event {
    background-color: $red1-color;
    border: 2px solid $red1-color;
  }

  a.testorder-event {
    background-color: $pink1-color;
    border: 2px solid $pink1-color;
  }

  a.reservedorder-event {
    background-color: $purple1-color;
    border: 2px solid $purple1-color;
  }

  a.stoppedorder-event {
    background-color: $yellow1-color;
    border: 2px solid $yellow1-color;
  }

  /*
  a.productionorder-event:hover {
    background-color: $blue4-color;
    border: 2px solid $blue4-color;
  }
  */

  a.maintenanceorder-event:hover {
    background-color: $orange4-color;
    border: 2px solid $orange4-color;
  }

  a.qualityorder-event:hover {
    background-color: $red4-color;
    border: 2px solid $red4-color;
  }

  a.testorder-event:hover {
    background-color: $pink4-color;
    border: 2px solid $pink4-color;
  }

  a.reservedorder-event:hover {
    background-color: $purple4-color;
    border: 2px solid $purple4-color;
  }

  a.stoppedorder-event:hover {
    background-color: $yellow4-color;
    border: 2px solid $yellow4-color;
  }

  a.production-event-unplanned {
    border-left: 3px solid $blue1-color;
    background-color: $blue4-color;
    border: 2px solid $blue4-color;
  }

  a.production-event-working {
    border-left: 3px solid $green1-color;
    background-color: $green2-color;
    border: 2px solid $green2-color;
  }
  
  a.production-event-finished {
    border-left: 3px solid $yellow-color;
    background-color: $grey3-color;
    border: 2px solid $grey3-color;
  }

  a.production-event-validated {
    border-left: 3px solid $yellow-light-1;
    background-color: $grey3-color;
    border: 2px solid $grey3-color;
  }

  a.production-event-locked {
    border-right: 5px solid $grey2-color;
  }

  a.production-event-qualitycontrol {
    border-top: 4px solid $orange2-color;
  }

  a.production-event-filtered {
    border-bottom: 2px solid $red1-color;
    background-color: $red1-color;
    border: 2px solid $red1-color;
  }

  // lines
  .fc-timegrid-slots tr:nth-child(odd) {
    border-top: 1px solid $lines-input-color;
  }
  .fc .fc-timegrid-slots tr:last-child {
    border-bottom: 1px solid $lines-input-color;
  }

  // indicador do tempo
  .fc .fc-timegrid-now-indicator-arrow {
    border-color: red;
    border-top-color: transparent;
    border-bottom-color: transparent;
    z-index: 100000 !important;
  }
  .fc .fc-timegrid-now-indicator-line {
    border: 1px solid red;
    z-index: 100000 !important;
  }
  .fc-timegrid-col.fc-day {
    position: relative;
  }
  .fc-timegrid-col.fc-day::after {
    
    content: '';
    position: absolute;
    right: 1px;
    top: 0px;
    bottom: 0px;
    width: 1px;
    background-color: $lines-input-color;
    /*border-right: 1px solid #ccc;*/
  }
  .fc-timegrid-col.fc-day:last-child:after {
    content: '';
    position: absolute;
    right: 1px;
    top: 0px;
    bottom: 0px;
    width: 0px;
  }
  .fc .fc-timegrid-slot-label {
    /*transform: translateY(-12px);
    background-color: #fff;*/
  }
  .fc .fc-timegrid-slots tr:first-child .fc-timegrid-slot-label {
    /*transform: translateY(-4px);*/
  }
  .fc-timegrid-event {
    border-radius: 5px;
  }
  .fc-v-event, .fc-h-event {
    //border: 0px;
    //border-left: 3px solid $secondary-color;
    background-color: $secondary-light-3;
    color: black;
    padding: 5px 2px;
  }
  .fc-v-event:hover .fc-event-resizer:after,
  .fc-h-event:hover .fc-event-resizer:after {
    content: "";
    position: absolute;
    margin: 1px 0;
    width: .5em;
    height: .5em;
    background: #fff;
    border-radius: 50%;
    border: 1px solid;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .fc-event-select {
    position: relative;
  }

  .fc-event-select .fc-event {
    width: calc(100% - 36px);
    margin-left: 36px;
  }

  .fc-event-select .fc-event-checkbox {
    position: absolute;
    width: 36px;
    height: 100%;
    top: calc(50% - 12px);
    left: 0px;
  }
  
  .fc-theme-standard td, .fc-theme-standard th {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }

  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    position: relative;
  }

  .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
    position: absolute;
    padding: 2px;
    left: 0px;
    top: -2px;
    font-size: 11px;
    color: black;
  }

  // shifts
  .fc .fc-bg-event {
    background-color: $lines-input-color;
    border-radius: 3px;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 9px;
    border: 0px;
    opacity: 0,9;
  }
  
  // shifts hover
  .fc .fc-bg-event:hover { 
    background-color: $grey-color;
  }

  // stop
  .fc .fc-bg-event.background-stop,
  .fc .fc-h-event.shift-stop,
  .fc .fc-v-event.shift-stop {
    background-color: $grey-color-3;
    opacity: 0.5 !important;
    font-size: 9px;
    text-transform: uppercase;
    margin: 1px 2px 1px 0px;
    //padding-left: 2px;
    border-radius: 3px;
    font-weight: bold;

    .fc-event-main {
      color: #000000;
    }
  }
  
  @media (max-width: 1366px) {
    .fc-timegrid-event, .fc-timegrid-more-link {
      font-size: 11px;
    }

    .production-order-card {
      font-size: 11px;
      font-weight: bold;

      .col {
        padding-right: 10px;
        padding-left: 10px; 
      }

      h4 {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.shift-view, .shift-template-view {
  
  .card-header, .card-body {
    padding: 15px 0px;
  }

  .fc-timegrid-slots table {
    border-bottom: 1px solid #f7f7f7 !important;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    padding: 0px 0px 15px 0px;
  }

  .fc .fc-scrollgrid-section-liquid {
    height: auto;
  }

  .shift-event-unactive {
    border-left: 3px solid $grey2-color ;
    background-color: $grey3-color !important;
  }
}

.shift-template-view {
  .card-header, .card-body {
    padding: 15px 0px;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    padding: 0px 0px 15px 0px;
  }

  .fc-theme-standard td, .fc-theme-standard th {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  .fc .fc-scrollgrid-section-liquid {
    height: auto;
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: transparent;
  }
  
  .shift-event-unactive {
    border: 1px solid $grey2-color !important;
    border-left: 3px solid $grey2-color;
    background-color: $grey3-color !important;
  }
}

.fc-event.production-order-card {
  padding: 5px 10px;
  background-color: $primary-light-4;
  margin: 10px 0px;
}

.production-view {
  table th, .table th, .table thead th {
    background-color: transparent;
  }

  .card-header, .card-body {
    padding: 15px 15px 0px 15px;
    border-radius: 5px !important;
  }

  .card-footer .btn {
    padding: 0px;
  }

  .calendar-container > .card > .card-body {
    padding-left: 0px;
    padding-bottom: 15px;
  }

  .fc-theme-standard .fc-datagrid-header td, .fc-theme-standard .fc-datagrid-header th,
  .fc-theme-standard .fc-timeline-header-row td, .fc-theme-standard .fc-timeline-header-row th {
    // border-bottom: 1px solid transparent;
    // border-left: 1px solid transparent;
    // border-right: 1px solid transparent;
  }

  .fc-theme-standard td, .fc-theme-standard th {
    // border-left: 1px solid transparent;
    // border-right: 1px solid transparent;
  }

  .fc .fc-scrollgrid-section-liquid {
    height: 1px;
  }

  .fc-theme-standard .fc-resource-timeline .fc-scrollgrid-section-header {
    background-color: #f7f7f7;
    height: 50px;
  }

  .fc-scroller .fc-datagrid-header tr, .fc-scroller .fc-timeline-header tr {
    // border-bottom: 0px;
  }
  
  // cards   // production order cards
  .production-order-card {
    padding: 5px 10px;
    background-color: $primary-light-3 !important;
    border-color: $grey-color !important;
    border-radius: 5px;
    margin: 8px 0px;
    font-size: 11px;
    font-weight: 100;

     .btn .c-icon {
      //color: $primary-color;
    }
    h4 small {
      color: black;
    }
  }

  .fc-event {
    background-color: $blue1-color;
    border: 1px solid $blue2-color;
    color: #000000;
  }

  .fc-event:hover {
    background-color: $green4-color;
    color: #000000;
  }

  .fc-event .fc-event-main {
    color: #000000;
  }

  .fc-event:hover .fc-event-main {
    color: #000000;
  }

  .fc-event:last-child {
    margin-bottom: 0px;
  }

  .fc-v-event, .fc-h-event {
    //border: 0px;
    // border-left: 3px solid $primary-light-5;
    // background-color: $primary-light-5;
    border-left: 3px solid $blue1-color; 
    background-color: $blue2-color;
    padding: 5px 2px;
  }

  a.workorder-event {
    background-color: $grey2-color !important;
  }

  a.productionorder-event {
    background-color: $blue2-color !important;
  }

  a.maintenanceorder-event {
    background-color: $orange1-color !important;
  }

  a.qualityorder-event {
    background-color: $red1-color !important;
  }

  a.testorder-event {
    background-color: $pink1-color !important;
  }

  a.reservedorder-event {
    background-color: $purple1-color !important;
  }

  a.stoppedorder-event {
    background-color: $yellow1-color !important;
  }

  /*
  a.productionorder-event:hover {
    background-color: $blue4-color !important;
  }
  */

  a.maintenanceorder-event:hover {
    background-color: $orange4-color !important;
  }

  a.qualityorder-event:hover {
    background-color: $red4-color !important;
  }

  a.testorder-event:hover {
    background-color: $pink4-color !important;
  }

  a.reservedorder-event:hover {
    background-color: $purple4-color !important;
  }

  a.stoppedorder-event:hover {
    background-color: $yellow4-color !important;
  }

  a.production-event-unplanned {
    border-left: 3px solid $blue1-color;
    background-color: $blue4-color !important;
  }

  a.production-event-working {
    border-left: 3px solid $green1-color;
    background-color: $green2-color !important;
  }
  
  a.production-event-finished {
    border-left: 3px solid $yellow-color;
    background-color: $grey3-color !important;
  }

  a.production-event-validated {
    border-left: 3px solid $yellow-light-1;
    background-color: $grey3-color !important;
  }

  a.production-event-locked {
    border-right: 5px solid $grey2-color;
  }

  a.production-event-qualitycontrol {
    border-top: 4px solid $orange2-color;
  }

  a.production-event-filtered {
    border-bottom: 2px solid $red1-color;
    background-color: $red1-color !important;
  }

  .fc-event.fc-unselectable.fc-event-dragging {
    margin-top: -130px !important;
    margin-left: -265px !important;
  }

  .shift-template {
    background-color: $grey-color !important;
    opacity: 0.5 !important;
    border: 1px solid $grey-color  !important;
  }

  .shift-template:hover {
    background-color: $grey-color-3 !important;
    border: 1px solid $grey-color-3 !important;
  }

}

.c-sidebar-minimized ~ .c-wrapper .production-view .fc-event.fc-unselectable.fc-event-dragging {
  margin-left: -85px !important;
}

.calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-button{
  border: 1px solid $lines-input-color;
  color: $lines-input-color;
  background-color: $grey-color;
  //padding: 0px 5px;

}
.calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-button:hover {
  background-color: $primary-color !important;
}
.calendar-component .fc .calendar-btn.active {
  background-color: transparent !important;
  border-radius: 5px;
}

.calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-button:active {
  
}

// buttons

.calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-button.active, .calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-button:active, .calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-button.active, .calendar-component .fc .fc-toolbar.fc-header-toolbar .fc-button:focus {
  background-color: $primary-color !important;
}

.calendar-component .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child):active, .calendar-component .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child):focus {
  background-color: $primary-color !important;
}

.fc .fc-button-group > .fc-button:focus, .fc .fc-button-group > .fc-button:active, .fc .fc-button-group > .fc-button.fc-button-active {
  background-color: $primary-color !important;
}