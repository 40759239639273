.basic-multi-select {
    /*max-height: 45px;*/
    z-index: initial !important;

    .customReactSelectMenu {
        background-color: #fff;
        z-index: 11 !important;

        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
    }
    
    .select__control, .select__indicators {
        z-index: initial !important;
    }

    .select__menu-list {
        background-color: #fff;
        z-index: 11 !important;
    }

    .select__option {
        background-color: #fff;
        z-index: 11 !important;
    }

    .customReactSelectMenu + .customReactSelectMenu {
        z-index: 12 !important;
    }
}

.basic-single {
    /*max-height: 45px;*/
    z-index: initial !important;
    
    .customReactSelectMenu {
        background-color: #fff;
        z-index: 11 !important;
        
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
    }
    
    .select__control, .select__indicators {
        z-index: initial !important;
    }

    .select__menu-list {
        background-color: #fff;
        z-index: 11 !important;
    }

    .select__option {
        background-color: #fff;
        z-index: 11 !important;
    }

    .customReactSelectMenu + .customReactSelectMenu {
        z-index: 12 !important;
    }
}
