//  Button
.btn {
  color: #000;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px;
  border: 0px;
  //height: 40px !important;
}

.input-group-text .btn {
  padding: 0px;
}
.btn:focus, .btn.focus {
  box-shadow: initial;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 8px 11px;
}
.btn-primary {
  color: #000;
  background-color: $primary-color;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: $primary-color-light;
  //border-radius: 5px;
}

.btn-primary.active, .btn-primary:active {
  background-color: $primary-color-light;
}

.btn-primary.active:hover {
  background-color: $primary-color-light;
}

.btn-primary.active:focus {
  background-color: $primary-color-light;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,.btn-outline-danger:not(:disabled):not(.disabled):active{
  color:#fff;
  background-color: $danger-color;
  border-color: $danger-color
}


.btn-secondary {
  color: #000;
  background-color: $secondary-color;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: $secondary-color-hover;
  border: 0px;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: $black5-color;
}

.btn-success {
  color: #000;
  background-color: $primary-color;  
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: $primary-color-hover;
  border: 0px;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: $black5-color;
}

.btn-warning {
  color: #000;
  background-color: $warning-color;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: $warning-color-hover;
  border: 0px;
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: $black5-color;
}

.btn-danger {
  color: #000;
  background-color: $danger-color;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: $danger-color-hover;
  border: 0px;
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: $black5-color;
}

.btn-outline-primary{
  color: $primary-color;
  border: 2px solid $primary-color;
}
.btn-outline-primary:hover{
  color:#fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.btn-outline-primary.disabled,.btn-outline-primary:disabled{
  color: $primary-color;
  background-color:transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,.btn-outline-primary:not(:disabled):not(.disabled):active{
  color:#fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.show>.btn-outline-primary.dropdown-toggle{
  color:#fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.btn-outline-secondary{
  color: $secondary-color;
  border: 1px solid $secondary-color;
}
.btn-outline-secondary:hover{
  color:#4f5d73;
  background-color: $secondary-color;
  border-color: $secondary-color;
}
.btn-outline-secondary.disabled,.btn-outline-secondary:disabled{
  color: $secondary-color;
  background-color:transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,.btn-outline-secondary:not(:disabled):not(.disabled):active{
  color:#4f5d73;
  background-color: $secondary-color;
  border-color: $secondary-color
}
.show>.btn-outline-secondary.dropdown-toggle{
  color:#4f5d73;
  background-color: $secondary-color;
  border-color: $secondary-color
}
.btn-outline-success{
  color: $success-color;
  border: 1px solid $success-color
}
.btn-outline-success:hover{
  color:#fff;
  background-color: $success-color;
  border-color: $success-color
}
.btn-outline-success.disabled,.btn-outline-success:disabled{
  color: $success-color;
  background-color:transparent
}
.btn-outline-success:not(:disabled):not(.disabled).active,.btn-outline-success:not(:disabled):not(.disabled):active{
  color:#fff;
  background-color: $success-color;
  border-color: $success-color
}
.show>.btn-outline-success.dropdown-toggle{
  color:#fff;
  background-color: $success-color;
  border-color: $success-color
}
.btn-outline-info{
  color: $info-color;
  border: 1px solid $info-color
}
.btn-outline-info:hover{
  color:#fff;
  background-color: $info-color;
  border-color: $info-color
}
.btn-outline-info.disabled,.btn-outline-info:disabled{
  color: $info-color;background-color:transparent
}
.btn-outline-info:not(:disabled):not(.disabled).active,.btn-outline-info:not(:disabled):not(.disabled):active{
  color:#fff;
  background-color: $info-color;
  border-color: $info-color
}
.show>.btn-outline-info.dropdown-toggle{
  color:#fff;
  background-color: $info-color;
  border-color: $info-color
}
.btn-outline-warning{
  color: $warning-color;
  border: 1px solid $warning-color
}
.btn-outline-warning:hover{
  color:#4f5d73;
  background-color: $warning-color;
  border-color: $warning-color
}
.btn-outline-warning.disabled,.btn-outline-warning:disabled{
  color: $warning-color;
  background-color:transparent
}
.btn-outline-warning:not(:disabled):not(.disabled).active,.btn-outline-warning:not(:disabled):not(.disabled):active{
  color:#4f5d73;
  background-color: $warning-color;
  border-color: $warning-color
}
.show>.btn-outline-warning.dropdown-toggle{
  color:#4f5d73;
  background-color: $warning-color;
  border-color: $warning-color
}
.btn-outline-danger{
  color: $danger-color;
  border: 1px solid $danger-color
}
.btn-outline-danger:hover{
  color:#fff;
  background-color: $danger-color;
  border-color: $danger-color
}
.btn-outline-danger.disabled,.btn-outline-danger:disabled{
  color: $danger-color;
  background-color:transparent
}
.btn-outline-danger:not(:disabled):not(.disabled).active,.btn-outline-danger:not(:disabled):not(.disabled):active{
  color:#fff;
  background-color: $danger-color;
  border-color: $danger-color
}
.show>.btn-outline-danger.dropdown-toggle{
  color:#fff;
  background-color: $danger-color;
  border-color: $danger-color
}
.btn-outline-light{
  color:#ebedef;
  border:1px solid #ebedef
}
.btn-outline-light:hover{
  color:#4f5d73;
  background-color:#ebedef;
  border-color:#ebedef
}
.btn-outline-light.disabled,.btn-outline-light:disabled{
  color:#ebedef;
  background-color:transparent
}
.btn-outline-light:not(:disabled):not(.disabled).active,.btn-outline-light:not(:disabled):not(.disabled):active{
  color:#4f5d73;
  background-color:#ebedef;
  border-color:#ebedef
}
.show>.btn-outline-light.dropdown-toggle{
  color:#4f5d73;
  background-color:#ebedef;
  border-color:#ebedef
}
.btn-outline-dark{
  color: $black1-color;
  border: 1px solid $black1-color;
}
.btn-outline-dark:hover{
  color:#fff;
  background-color: $black1-color;
  border-color: $black1-color;
}
.btn-outline-dark.disabled,.btn-outline-dark:disabled{
  color: $black1-color;
  background-color:transparent
}
.btn-outline-dark:not(:disabled):not(.disabled).active,.btn-outline-dark:not(:disabled):not(.disabled):active{
  color:#fff;
  background-color: $black1-color;
  border-color: $black1-color;
}
.show>.btn-outline-dark.dropdown-toggle{
  color:#fff;
  background-color: $black1-color;
  border-color: $black1-color;
}

.btn.btn-link.btn-sm:hover {
  text-decoration:none;
  color:#000;
}

.btn.btn-link.btn-sm:focus {
  text-decoration:none;
}

.btn-pill {
  text-decoration: none;
  color: $primary-color;
  border: 2px solid $primary-color;
  padding: 0px;
  font-size: 12px;
  letter-spacing: 0.3px;
  height: 35px !important;
  padding: 5px 13px 5px 13px;
  float: right;

  .c-icon {
    width: 20px !important;
    height: 20px !important;
    color: $primary-color;
    margin-right: 6px !important;
    margin-top: 0px !important;
    margin-left: -2px;
  }
}

.btn-pill:hover {
  text-decoration: none;
  color: $primary-color-hover !important;
  border-color: $primary-color-hover !important;
}



//  Icons
.btn-lg i, .btn-group-lg > .btn i, .btn-lg .c-icon, .btn-group-lg > .btn .c-icon {
  width: 1.09375rem !important;
  height: 1.09375rem !important;
  margin: 0.27344rem 0.27344rem !important;
  margin-left: -10px !important;
  margin-right: 10px !important;
}


.btn-arrow {
  padding-top: 0 !important;
  width: auto !important;
  height: 35px !important;
  font-size: 16px;

  .c-icon {
    padding: 4px !important;
    width: 35px !important;
    height: 35px !important;
    margin-right: 12px;
    margin-top: 0px !important;
    color: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 5px;
  }
}

.btn-arrowLabel {
  margin-top: 0px;
  font-size: 16px !important;
  letter-spacing: 0.2px;
  margin-top: -4px !important;
  height: 40px !important;

  .c-icon {
    margin-right: 8px;
    margin-top: 1px !important;
    margin-left: 0px !important;
  }
}

.btn-arrowLabel:hover {
 color:#000;
}

.btn-square {
  border: 2px solid $primary-color;
  font-size: 14px !important;
  height: 35px !important;
  padding: 5px 13px 5px 10px;
  color: $primary-color;

  .c-icon {
    padding: 2px !important;
    width: 26px !important;
    height: 26px !important;
    margin: 0px !important;
    color: $primary-color;
    margin-right: 5px !important;
    margin-left: -2px !important;
    margin-top: -3px !important;
  }
}
