
.dashboard-widget.toepicker-component.row {
    
    .custom-select {
        background-color: white !important;
        //border: 2px solid $grey-color-dark!important ;
        border: 1px solid $input-boxes !important;
        border-radius: 5px;
        font-size: 12px;
        padding-left: 12px;
    }
}

