.dropdown-header {
  padding: 12px;
  font-size: 14px;
}
.dropdown-item {
  padding: 11px 1.25rem;
  color: $black1-color;
  border-bottom: 1px solid $black4-color;
}
.dropdown-item:last-child {
  border-bottom: 0px;
}

.dropdown-item.active, .dropdown-item:active {
  color: $black2-color;
  background-color: $black4-color;
}
.dropdown-item:hover, .dropdown-item:focus,
.dropdown-item:hover a, .dropdown-item:focus a {
  color: $black1-color;
  background-color: $grey7-color;
}
.dropdown-divider {
  margin: 0px;
}

.dropdown-menu {
  border-radius: 20px;
}
