
// Form Layout

.formlayout-table th, .formlayout-table td {
  border: 1px solid rgb(255, 255, 255) !important;
}

.formlayout-table table th, .formlayout-table table td {
  border: 1px solid rgb(255, 255, 255) !important;
  border-bottom: 0px !important;
}

.formlayout-table table th:first-child, .formlayout-table table td:first-child {
  border-right: 0px !important;
  border-left: 0px !important;
}

.formlayout-table table th:last-child, .formlayout-table table td:last-child {
  border-right: 0px !important;
}

.formlayout-table .table tr:hover {
  background: transparent;
}

.formlayout-table table th, .formlayout-table table td {
  border: 0px !important;
}

.formlayout-table table th, .formlayout-table table td {
  border: 0px !important;
}

.lumitiot-formtable {

  table th,
  .table th,
  .table thead th {
    color: #000;
    border: 0px;
    border-radius: 0px !important;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 15px;
    text-align: center;
  }
  
  table thead td:first-child {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5 !important;
  }
  
  table thead tr:nth-child(2) th,
  .table thead tr:nth-child(2) th,
  .table thead tr:nth-child(2) th {
    background: $primary-light-1 !important;
  }
  
  thead tr:nth-child(1) th,
  thead tr:nth-child(2) th {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #f5f5f5;
  }
  
  thead tr:nth-child(3) th {
    // text-align: right;
    text-align: center;
    background-color: $primary-light-3 !important;
  }
  
  tbody tr th {
    background-color: $primary-light-4 !important;
    text-align: right;
    vertical-align: middle;
  }
  
  tbody tr td {
    text-align: center;
    font-size: 12px;
  }
  
  tfoot tr th {
    text-align: right;
    vertical-align: middle;
    background-color: $primary-light-1 !important;
  }
  
  tfoot tr:nth-child(2) th {
    background-color: $primary-light-3 !important;
  }
  
  tfoot tr:nth-child(3) th {
    background-color: $primary-light-5 !important;
  }
  
  tfoot tr:nth-child(4) th {
    background-color: $grey7-color;
  }
  
  tfoot tr td {
    text-align: right;
  }
  
}

//add variables
.btn.btn-secondary.btn-sm.btn-block {
  background-color: white;
  border: 1px solid $black1-color;
  margin-bottom: 20px;
  margin-top: 5px;
  width: auto;
  padding: 8px 17px 7px 12px;
}
//icon add variables
.c-icon.iconButton{
  padding: 0px !important;
  width: 21px !important; 
  height: 21px !important;
  margin-top: 0px !important;
  margin-right: 5px;
}

//dragable fields
.actions-wrapper {
  border: 1px solid $grey3-color;
  background-color: white;
  padding: 0px;
}

.actions-entry {
  //border: 1px solid $grey2-color;
  border: 1px solid white;
  background-color: $primary-light-4 !important;
  margin-right: 5px;

}

.actions-entry {
  .form-group { 
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

/*
.action-entry-delete.float-right.btn.btn-dark.btn-sm {
  //background-color: $grey4-color;
  margin-right: 5px;
  margin-top: -7px;
  background-color: white;
  padding: 7px 6px 6px 6px;
  font-size: 13px;
  font-weight: 100;
  //border: 1px solid black;
}
*/
.action-entry-delete.float-right.btn.btn-dark.btn-sm:hover {
  color:black;
}

label {
  margin-top: 10px;
}

#test-table-xls-button {
  background-color: $grey-color;
  color: black ;
  font-size: 12px !important;
  height: 35px;
  margin: 0px;
  padding: 0px 10px;
}

#test-table-xls-button:hover {
  background-color: $primary-color;
}

// h4 {
//   font-size: 12px !important;
// }
