
.DraggableTags-tag-drag {
  background-color: #fff;
}

.rawmaterialmap-component {
  .input-group .input-group-prepend .input-group-text,
  .input-group .input-group-append .input-group-text {
    color: #000;
    /*
    background-color: transparent;
    border: 0px;
    */
  }

  .table {
    background-color: #fff;
    margin-bottom: 0px;
    height: calc(100% - 53px);
  }
  
  .table tr th {
    border: 1px solid $white2-color;
    line-height: 35px;
    padding: 0px;
    text-align: center;
    min-width: 15px;
  }

  .table tr td {
    border: 1px solid $white2-color;
    border-top: 0px;
    border-right: 0px;
    padding: 0px;
    text-align: center;
    min-width: 15px;
    line-height: 32px;
  }

  .table tr:nth-child(2) th {
    border-bottom: 1px solid $black3-color;
  }

  .table tr td {
    border-right: 1px solid $black3-color;
    border-bottom: 1px solid $black3-color;
    border-top: 1px solid $black3-color;
  }

  .table tr td .btn {
    padding: 0px 0px;
    width: 100%;
    height: 100%;
    line-height: 35px;
  }

  .table tr th.week:first-child,
  .table tr td.week:first-child {
    padding: 0 5px;
  }

  .table tr th.week, .table tr td.week {
    border-right: 1px solid #d9d9d9;
  }

  .table tr td {
    background-color: #fafafa;
  }

  .table tr td.week {
    background-color: #fff;
  }

  .table tr td.weekend {
    background-color: #f5f5f5;
  }

  .table tr td.has-alarms {
    background-color: $info-color;
  }
  
  .table tr td.has-alarms-warning {
    background-color: $warning-color;
  }
  
  .table tr td.has-alarms-danger {
    background-color: $danger-color;
  }

  .table tr td.has-alarms.day-selected {
    background-color: $info-color-hover;
  }
  
  .table tr td.has-alarms-warning.day-selected {
    background-color: $warning-color-hover;
  }

  .table tr td.has-alarms-danger.day-selected {
    background-color: $danger-color-hover;
  }

  .CrossArea .table td {
    min-width: 25px;
  }
  
  .CrossArea .square {
    border: 0px;
    margin: auto;
    min-width: 88px;
    height: 32px;
    padding: 0px;
    overflow-wrap: break-word;
    background-color: #fff;
  }

  .CrossArea .square:nth-child(2) {
    border-top: 1px solid $black4-color;
  }

  .DraggableTags-tag {
    display: block;
    float: none;
    height: 31px;
    overflow-wrap: break-word;
    font-size: 12px;
    text-align: left;

    .tag {
      padding: 0px 5px;
    }

    .tag-remove {
      position: absolute;
      right: 2px;
      top: 0px;
      cursor: pointer;
      color: #000;
      background-color: #fff;

      .c-icon {
        width: 12px;
        height: 12px;
      }
    }

    .has-conflict {
      background-color: $red4-color;
    }
  }

  .DraggableTags-tag-drag {
    height: 29px;
    line-height: 31px;
    overflow-y: hidden;
  }

  .drag-source {
    /*
    overflow-x: visible;
    overflow-y: auto;
    */

    .DraggableTags {
      width: calc(100% - 10px);
      width: 100%;
    }

    .DraggableTags-tag {
      display: inline-block;
      float: left;
      border: 1px solid #ccc;
      text-align: left;
      margin: 5px;
      width: calc(50% - 10px);
    }

    .DraggableTags-tag-drag {
      padding: 0 5px;
    }
  }
  
  .button,
  .btn-group .button {
    padding: 0.4em 0.65em;
    border-radius: 5px !important;
    border: none;
    color: black;
  }
  
  .btn-outline-link {
    border: none;
    background-color: $grey-color !important;
    color: black;
    height: 35px;
    text-transform: none;
    font-weight: 100;
  }

  .btn-outline-link.active {
    background-color: $primary-color !important;
    color: black !important;
    
  }
  .btn-outline-link:hover {
    background-color: $primary-color !important;
    color: black !important;
  }

  .btn-outline-link {
    margin-right: 15px;
  }
  
  .btn-outline-link:last-child {
    margin-right: 0px;
  }

  .dropdown-menu {
    border-radius: 0px;
    border-color: #000;
    padding: 0px;
  }

  // botão export
  .dropdown:last-child .btn-outline-link {
    margin-right: 0px;
    border: none;
    color: black;

    .c-icon {
      padding: 0px;
      margin-top: -2px;
    }
  }
 
  .ag-theme-alpine {

    .ag-header-container, .ag-header-viewport, .rawmaterialmap-component .ag-theme-alpine .ag-header, .ag-cell-label-container  {      
      background-color: $primary-color;
      min-height: 40px !important;
      padding-left: 0px !important;
    } 

    .ag-header, .ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
      height: 40px !important;
      min-height: 40px !important;
      padding-left: 0px !important;
    }

    .ag-root-wrapper {
      border: 0px;
    }
  
    // h2.toolbar-title {
    //   font-size: 14px;
    //   line-height: 35px;
    //   margin-bottom: 0px;
    //   text-transform: uppercase;
    //   font-weight: bold;
    // }

    .ag-theme-alpine .ag-ltr .ag-column-drop-horizontal {
      margin-bottom: 15px;
      border-radius: 5px;
    }

    .ag-theme-alpine .ag-side-buttons {
      background-color: #F5F5F5 ;
      border-radius: 0px 5px 5px 0px;
    }

    .ag-theme-alpine .ag-ltr .ag-filter-toolpanel-search {
      height: 40px !important;
    }

    .ag-theme-alpine .ag-checkbox-input-wrapper input, .ag-theme-alpine .ag-checkbox-input-wrapper input {
      background-color: $primary-color;
    }

    .ag-theme-alpine .ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button, .ag-theme-alpine .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
      border-left-color: red !important;
    }

    .ag-theme-alpine .ag-side-buttons {
      padding-top: 0px !important;
    }
     .ag-ltr .ag-column-drop-horizontal {
      border-radius: 5px !important;
    }
  }
}