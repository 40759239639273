


.DraggableTags-tag-drag {
  background-color: #fff;
}

.manageresources-component {

  .input-group .input-group-prepend .input-group-text,
  .input-group .input-group-append .input-group-text {
    color: #000;
  }

  .spinner-border {
    width: 16px;
    height: 16px;
  }

  .table {
    background-color: #fff;
    margin-bottom: 0px;
    height: calc(100% - 53px);
    margin-top: 5px;
  }
  
  .table tr {
    border-radius: 5px;
    line-height: 35px;
    padding: 0px;
    text-align: center;
    min-width: 15px;
  }

  .table th {
    border-right: 1px solid $lines-input-color;
    border-radius: 5px;
    line-height: 35px;
    padding: 0px;
    text-align: center;
    min-width: 15px;
  }

  .table th:last-child {
    border: 0px;
  }

  .table td:last-child {
    border-right: 1px solid $lines-input-color;
  }

  .table  td {
    border: 1px solid $lines-input-color;
    border-top: 0px;
    border-right: 0px;
    padding: 0px;
    text-align: center;
    min-width: 15px;
    line-height: 32px;
  }

  .table tr:nth-child(2) th {
    border-bottom: 1px solid $lines-input-color;
  }

  .table tr td .btn {
    padding: 0px 0px;
    width: 100%;
    height: 100%;
    line-height: 31px;
  }

  .table tr th.week:first-child,
  .table tr td.week:first-child {
    padding: 0 5px;
  }

  .table tr td {
    background-color: $background;
  }

  .table tr th.week {
    vertical-align: middle;
  }

  .table tr td.week {
    background-color: $background;
  }

  .table tr td.weekend {
    background-color: $primary-light-5;
  }

  .table tr td.has-alarms {
    background-color: $info-color;
  }
  
  .table tr td.has-alarms-warning {
    background-color: $warning-color;
  }
  
  .table tr td.has-alarms-danger {
    background-color: $danger-color;
  }

  .table tr td.has-alarms.day-selected {
    background-color: $info-color-hover;
  }
  
  .table tr td.has-alarms-warning.day-selected {
    background-color: $warning-color-hover;
  }

  .table tr td.has-alarms-danger.day-selected {
    background-color: $danger-color-hover;
  }

  .CrossArea .table td {
    min-width: 25px;
  }
  
  .CrossArea .square {
    border: 0px;
    margin: auto;
    min-width: 88px;
    height: 32px;
    padding: 0px;
    overflow-wrap: break-word;
    background-color: transparent;
  }

  .CrossArea .square {
    border-top: 1px solid $black4-color;
  }

  .CrossArea .square:nth-child(1) {
    border-top: 0px;
  }

  .drag-source .DraggableTags-tag {
    border: 1px solid $lines-input-color;
  }

  .DraggableTags-tag {
    display: block;
    float: none;
    height: 31px;
    overflow-wrap: break-word;
    font-size: 11px;
    text-align: left;
 
    border-radius: 3px;

    .tag {
      padding: 0px 5px;
      border-color: 0px !important;
    }

    .tag-title {
      padding: 0px 12px 0px 0px;
    }

    .tag-info {
      position: absolute;
      left: 2px;
      top: 8px;
      cursor: pointer;
      color: #000;
      background-color: #fff;

      line-height: 12px;
      width: 12px;
      height: 12px;
      
      .c-icon {
        width: 12px;
        height: 12px;
      }
    }

    .tag-remove {
      position: absolute;
      right: 2px;
      top: 8px;
      cursor: pointer;
      color: #000;
      background-color: #fff;
      
      line-height: 12px;
      width: 12px;
      height: 12px;
      
      .c-icon {
        width: 12px;
        height: 12px;
      }
    }

    .tag-select {
      position: absolute;
      right: 2px;
      top: 8px;
      cursor: pointer;
      color: #000;
      background-color: #fff;
   
      line-height: 12px;
      width: 12px;
      height: 12px;
      
      .c-icon {
        width: 12px;
        height: 12px;
      }
    }

    .has-selected {
      border-bottom: 2px solid $red1-color;
      background-color: $red1-color;
    }

    .has-conflict {
      background-color: $red4-color;
    }

    .has-selected .tag-remove,
    .has-selected .tag-select {
      background-color: $red1-color;
    }
    
    .has-conflict .tag-remove,
    .has-conflict .tag-select {
      background-color: $red4-color;
    }
  }

  .DraggableTags-tag-drag {
    height: 29px;
    line-height: 31px;
    overflow-y: hidden;
  }

  .drag-source {
    /*
    overflow-x: visible;
    overflow-y: auto;
    */

    .DraggableTags {
      width: calc(100% - 10px);
      width: 100%;
    }

    .DraggableTags-tag {
      display: inline-block;
      float: left;
      text-align: left;
      margin: 5px;
      width: calc(50% - 10px);

    }

    .tag-title {
      padding: 0 5px;
    }

  }
  
  .button,
  .btn-group .button {
    padding: 0.4em 0.65em;
    border-radius: 5px !important;
  }

  .btn-outline-link {
    border: 1px solid;
    background-color: $grey-color !important;
    border: 0px !important; 
    color: black !important;
    margin-right: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    height: 35px;
    width: 35px;

    .c-icon {
      padding: 0px;
      margin-left: -1px;
    }
  }

  .btn-outline-link:hover { 
    background-color: $primary-color !important;
    color: black !important;
  }

  .btn-outline-link.active {
    background-color: $primary-color !important;
    color: #000000 !important;
  }

  .btn-outline-link:last-child {
    margin-right: 0px;
  }

  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 5px !important;
  }

  .dropdown-menu {
    border-radius: 0px;
    border-color: #000;
    padding: 0px;
  }

  .dropdown .btn-outline-link {
    //padding: 5px;
  }

  .dropdown:last-child .btn-outline-link {
    margin-right: 0px;
  }
  
  .dropdown-item a {
    color: #000;
  }

  h2.toolbar-title {
    font-size: 14px !important;
    line-height: 35px;
    text-transform: uppercase;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 13px;
    margin-bottom: 10px;
    font-weight: bold;
  }
}
