//  Table



.table tr td {
  line-height: 30px !important;
  background-color: white;
  border-bottom: 1px solid $grey-color;  
}

.table tr td .btn {
  padding: 0px 10px;

  .c-icon {
    padding: 1px;
  }
}

table th, .table th, .table thead th {
  background: $primary-color;
  color: #000;

  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 15px;
}


table thead tr:nth-child(2) th, .table thead tr:nth-child(2) th, .table thead tr:nth-child(2) th {
  background: #fff;
}

table td, .table td {
  color: #000;
  padding: 5px 0.75rem;
  border: 0px;
  font-size: 12px;
  font-weight: 400;
  vertical-align: middle;
  padding-left: 15px;
}


@media (hover: hover), not all {
  .table .btn:hover {
      color: #000;
      text-decoration: none;
  }
}
table thead tr:nth-child(2) .form-control {
  color: #B5B5B5;
  height: 40px;
  padding: 10px 15px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0px;
}

// CDataTable arrow
.CDataTable_arrow-position__3dQ_L {
  right: auto !important;
  width: 15px;
  margin-left: 10px;
  color: black;
}

.CDataTable_transparent__OxOFN {
  opacity: 1 !important;
  width: 12px;
}

// search-toolbar
.search-toolbar .btn {
  padding: 0px; 
  margin-right: 15px;
  width: 40px;  
  height: 40px;  
}

.iconFilter{
  font-size: 2rem !important;
}

button.btn.btn-large.btn-block.active {
  border: 1px solid white;
  background-color: $primary-color;
}

.search-toolbar .form-control {
  margin-bottom: 5px;
  height: 40px;
  border-color: $grey-color-dark;
  border-radius: 5px !important;
  font-size: 12px;
}

.search-toolbar .form-control:focus {
  border-color: $black2-color;
}

button.btn.btn-large.btn-block {
  border: 1px solid $primary-color;
  width: 40px;
  color: $primary-color;
  float: right;
}
button.btn.btn-large.btn-block:hover {
  background-color: $primary-color;
  border: 1px solid $primary-color;
  color: white;
}

.filter-tokenizer:first-child {
  border-radius: 5px !important;
  border-color: $grey-color-dark;
}

//add action
.btn.btn-secondary.btn-sm.btn-block {
  background-color: white;
  border: 1px solid $black1-color;
  margin-bottom: 20px;
  margin-top: 5px;
  width: auto;
  padding: 8px 17px 7px 12px;
}

//icon add variables
.c-icon.iconButton{
  padding: 0px !important;
  width: 21px !important; 
  height: 21px !important;
  margin-top: 0px !important;
  margin-right: 5px;
}

//dragable fields
.actions-wrapper {
  border: 1px solid $grey3-color;
  background-color: white;
  padding: 0px;
  margin-bottom: 10px;
}

.actions-entry {
  border: 1px solid white;
  background-color: $green4-color !important;
  margin-right: 5px;
}

.actions-entry {
  .form-group { 
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

//cancel button
.action-entry-delete.float-right.btn.btn-dark.btn-sm {
  margin-right: -2px;
  margin-top: -3px;
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjM1N3B4IiBoZWlnaHQ9IjM1N3B4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGcgaWQ9ImNsb3NlIj4NCgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zIA0KCQkJMjE0LjIsMTc4LjUgCQkiLz4NCgk8L2c+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center;
  width: 20px;
  height: 20px;
  padding: 0px;
}

.action-entry-delete.float-right.btn.btn-dark.btn-sm:hover {
  color:black;
}

.actions-danger {
  background-color: $red4-color !important;
}

.pagination {
  margin-bottom: 15px;
  margin-left: 5px;
  font-size: 12px;
}

