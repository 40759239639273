//  Background
.bg-primary {
  color: #000;
  background-color: $primary-color !important;
}

.bg-secondary {
  color: #000;
  background-color: $secondary-color !important;
}

.bg-info {
  color: #000;
  background-color: $info-color !important;
}

.bg-success {
  color: #000;
  background-color: $success-color !important;
}

.bg-warning {
  color: #000;
  background-color: $warning-color !important;
}

.bg-danger {
  color: #000;
  background-color: $danger-color !important;
}
