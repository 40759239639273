

.statusfilter-component-vertical {
    margin-left: -15px;
    padding: 0px;
    
    .btn.btn-link.btn-sm {
        background-color: white;
        margin-bottom: 5px;
        line-height: 4;
        width: 100%;
        display: block;
        text-align: left;
        padding-left: 20px;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        .value {
            margin-left: 20px;
        }
        .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
          width: 12px;
          height: auto;
        }
      }
    }
// status filter horizontal
.statusfilter-component {

    .btn.btn-link.btn-sm {
        background-color: white;
        width: 100%;
        display: block;
        text-align: left;
        padding-left: 20px;
        border-radius: 5px !important;
        margin: 0px;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        border-radius: 0px;
        height: 100%;
        .value {
            margin-left: 20px;
        }
    }
    .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
      width: 14px !important;
      height: auto;
    }
  }

//Status filter
.statusfilter-component {
    height: 100%;

    > div > .row {
        height: 100%;
    }
  
    .btn.btn-link.btn-sm {
        //background-color: transparent;
        width: 100%;
        display: block;
        text-align: left;
        padding-left: 13px;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        height: 100%;
        .value {
            margin-left: 10px;
     
        }
    }
}


.statusfilter-component .active.btn {
    background-color: #ccc;
}

.Status_Filter--MobileView {
    width: 100%;
    height: 100%;
    margin: 0;
    .col-12 {
        padding: 0;
    }
    .col-12, .Select__Input--Component, .Select__control {
        height: 100%;
    }
    .Select__Input--Component {
        width: 100%;
    }
    h6 {
        margin-bottom: 0px;
        margin-top: 2px;
        margin-left: 5px;
        font-size: 12px !important;
        font-weight: bold;
    }
    .Options__Counter {
        margin-left: 8px;
    }
    .Options__Container {
        display: flex;
        align-items: center;
        .Filter__Color {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin: 8px 12px 8px 0;
        }
    }
    .Select__menu {
        width: 100%;
        overflow: hidden;
        z-index: -1;
    }
    .Select__menu-list {
        padding-bottom: 0px;
        padding: 0;
    }
    .Select__control {
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        .Filter__Color {
            background-color: white !important;
        }
    }
    .Select__indicator-separator {
        display: none;
    }
}

@media (max-width: 720px) {
    .Status_Filter--MobileView .Select__control {
        border-radius: 5px;
    }
    .statusfilter-component {
    
      div > .row {
        display: block !important;
        margin-bottom: 10px;
        min-height: 400px !important;
      }
      .btn.btn-link.btn-sm {
          background-color: $dark;
          width: 100%;
          display: inline-block;
          text-align: left;
          border-radius: 5px !important;
          padding: 15px !important;
          margin-bottom: 8px;
          font-weight: bold;
          font-size: 10px;
          text-transform: uppercase;
          border-radius: 0px;
          height: 100%;
          .value {
              margin-left: 10px !important;
          }
        }
    
      .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
        width: 12px;
        height: auto;
        }
      }
    }

    @media (max-width: 720px) {
        .Status_Filter--MobileView .Select__control {
            border-radius: 5px;
        }
        .statusfilter-component {

            
        
          div > .row {
            display: block !important;
            margin-bottom: 10px;
            min-height: 400px !important;
          }
          .btn.btn-link.btn-sm {
              background-color: $dark;
              width: 100%;
              display: inline-block;
              text-align: left;
              border-radius: 5px !important;
              padding: 15px !important;
              margin-bottom: 8px;
              font-weight: bold;
              font-size: 10px;
              text-transform: uppercase;
              border-radius: 0px;
              height: 100%;
              .value {
                  margin-left: 10px !important;
              }
            }
        
          .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
            width: 12px;
            height: auto;
            }
          }
        }