//  Switch
.c-switch, .c-switch-3d-primary {
  padding-left: 0px;
}
.c-switch-primary .c-switch-input:checked + .c-switch-slider,
.c-switch-3d-primary .c-switch-input:checked + .c-switch-slider {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.c-switch-secondary .c-switch-input:checked + .c-switch-slider,
.c-switch-3d-secondary .c-switch-input:checked + .c-switch-slider {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
}

.c-switch-info .c-switch-input:checked + .c-switch-slider,
.c-switch-3d-info .c-switch-input:checked + .c-switch-slider {
  background-color: $info-color !important;
  border-color: $info-color !important;
}

.c-switch-success .c-switch-input:checked + .c-switch-slider,
.c-switch-3d-success .c-switch-input:checked + .c-switch-slider {
  background-color: $success-color !important;
  border-color: $success-color !important;
}

.c-switch-warning .c-switch-input:checked + .c-switch-slider,
.c-switch-3d-warning .c-switch-input:checked + .c-switch-slider {
  background-color: $warning-color !important;
  border-color: $warning-color !important;
}

.c-switch-danger .c-switch-input:checked + .c-switch-slider,
.c-switch-3d-danger .c-switch-input:checked + .c-switch-slider {
  background-color: $danger-color !important;
  border-color: $danger-color !important;
}
