//   Header
.c-header {
  border-bottom: 0px;
  min-height: 51px;
}

.c-header, .c-header-nav {
  min-height: 51px;
}

.c-header-searchbar {
  margin-left: 12px;
}
.c-header-searchbar input {
  border-color: transparent;
}
.c-header-searchbar input:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: initial;
}
//links header
.c-header-searchbar .btn {
  border-color: transparent;
  padding: 0px;
  border-radius: 0px;
  font-weight: 100;
  color: $grey1-color;
  //text-transform: uppercase;
  line-height: 40px;
}

.c-header {
  .toolbar-link {
    height: 100%;

    .nav-link {
      padding: 0.5rem 0.5rem;
    }
  }
  .active.toolbar-link {
    background-color: $primary-color;
  }
  .navbar {
    padding: 0 1rem;
  }
}

.c-header-rightnav {
  margin-right: 15px; 

}
.c-header-rightnav .c-header-nav-item {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

  // administrador / user
.c-header-rightnav .c-header-nav-items {
  margin-left: 15px !important;
}

.c-header-rightnav .c-header-nav-link, .c-header-rightnav .c-header-nav-btn {
  padding-right: 0;
  padding-left: 0;
  color: black !important;
}

// avatar admnistrador / user
.c-header-rightnav .c-avatar {
  margin-right: 5px;
  //background-color: red;
}

.profile-name {
  background-color: #f5f5f5;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
}

html:not([dir="rtl"]) .c-header-rightnav .dropdown-toggle::after {
  margin-left: 10px;
}
.c-header-rightnav .header-separator {  
  width: 1px;
  height: 25px;
}
.c-header .dropdown-menu,
.navbar .dropdown-menu,
[data-display^="static"] ~ .dropdown-menu {
  padding: 0px;
  border-radius: 0px;
  border: 0px;
  filter: drop-shadow(0px 2px 1px #ddd);
}
.c-header .dropdown-menu::before,
.navbar .dropdown-menu::before,
[data-display^="static"] ~ .dropdown-menu::before {
  content:"\A";
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent $primary-color transparent;
  position: absolute;
  right: 10px;
  top: -8px;
}
.dropdown-toggle::after {
  display: none;
}




@media (max-width: 1000px) {
  .d-md-block {
    display: flex !important;
    background-color: $primary-color;
    width: 51px;
    
  }
  .c-header .c-header-toggler-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: -30px;
    margin-top: 6px;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMzIiIGhlaWdodD0iMzIiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTIxLjUsMzcuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsODAuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsMTIzLjYyNXYxMC43NWgxMjl2LTEwLjc1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+') 50% 50% no-repeat;
    background-size: 100%;
   }
   .c-header .c-header-toggler-icon:hover {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: -30px;
    margin-top: 6px;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMzIiIGhlaWdodD0iMzIiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTIxLjUsMzcuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsODAuNjI1djEwLjc1aDEyOXYtMTAuNzV6TTIxLjUsMTIzLjYyNXYxMC43NWgxMjl2LTEwLjc1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+') 50% 50% no-repeat;
    background-size: 100%;
   }
 
} 
 
@media (max-width: 790px) { 
  .c-header .c-header-toggler-icon {
  margin-left: 0px !important;
  }
}

@media (max-width: 570px) {
  .c-header-searchbar {
    margin-left: 0px !important;
    //padding-left: 12px !important;
  }  
}
