//   Card
.card {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
}
.card-header,
.card-body {
  padding: 25px 25px;
  border-radius: 5px !important;
  margin-top: 0px;
}
.slim-card-body {
  padding: 5px 15px;
  border-radius: 5px !important;
}
.card-footer{
  padding: 0px 15px 15px 15px;
  border: 0px;
}

.card-footer .btn:nth-child(1) {
  margin-right: 15px;
}

.dashboard-wrapper > .card > .card-body {
  padding: 0px;
}

.btn-primary {
  background-color: $primary-color ;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: $primary-color-hover ;
  //border-radius: 5px;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: $black5-color;
}
