
.maintenancecalendar-component {

  .input-group .input-group-prepend .input-group-text,
  .input-group .input-group-append .input-group-text {
    color: #000;
    background-color: white;
    border-radius: 5px 0px 0px 5px;
    /*
    background-color: transparent;
    border: 0px;
    */
  }
  
  .select-machine {
    border-radius: 5px;
  }

  .btn-machine-1 {
    border-radius: 5px 0px 0px 5px !important;
    background-color: $primary-color ;
  }

  .btn-machine-2 {
    border-radius: 0px 5px 5px 0px !important;
    background-color: $primary-color ;
  }

  .btn-machine-1:hover,  .btn-machine-2:hover {
    background-color: $primary-color-hover ;
    color: black !important;
  }

  html:not([dir="rtl"]) .input-group > .input-group-append > .btn, html:not([dir="rtl"]) .input-group > .input-group-append > .input-group-text, html:not([dir="rtl"]) .input-group > .input-group-prepend:not(:first-child) > .btn, html:not([dir="rtl"]) .input-group > .input-group-prepend:not(:first-child) > .input-group-text, html:not([dir="rtl"]) .input-group > .input-group-prepend:first-child > .btn:not(:first-child), html:not([dir="rtl"]) .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child){
   border-radius: 0px 5px 5px 0px;
 }
  .table {
    background-color: white;
  }
  
  .table tr th {
    line-height: 35px;
    padding: 0px;
    text-align: center;
    min-width: 15px;
  }

  .table tr td {
    border: 1px solid $lines-input-color !important;
    line-height: 35px;
    padding: 0px;
    text-align: center;
    min-width: 15px;
  }

  .table tr td:nth-last-child()

  .table tr td .btn {
    padding: 0px 0px;
    width: 100%;
    height: 100%;
    line-height: 35px;
  }

  .table tr th.week, .table tr td.week {
    border-right: 1px solid $lines-input-color;
  }

  .table tr td.weekend {
    background-color: $primary-light-4 !important;
  }

  .table tr td.has-alarms {
    background-color: $info-color;
  }
  
  .table tr td.has-alarms-warning {
    background-color: $warning-color;
  }
  
  .table tr td.has-alarms-danger {
    background-color: $danger-color;
  }

  .table tr td.has-alarms.day-selected {
    background-color: $info-color-hover;
  }
  
  .table tr td.has-alarms-warning.day-selected {
    background-color: $warning-color-hover;
  }

  .table tr td.has-alarms-danger.day-selected {
    background-color: $danger-color-hover;
  }
  
}
