
.oee-dashboard-component {
  padding: 0px;

  .c-header-searchbar {
    margin-left: 0px;
    padding-left: 0px;
    font-size: 12px;
  }

  .float-left:nth-child(2) {
    margin-top: 0px;
  }

  .float-left:nth-child(2) div:nth-child(1) {
    margin-top: 1px;
  }

  .float-left:nth-child(3) div:nth-child(1) {
    margin-top: 2px;
  }
  
  .float-left:nth-child(4) div:nth-child(1) {
    margin-top: 2px;
  }

  .btn-arrowLabel {
    margin-top: 0px !important;
    padding: 0px 0px 0px 0px!important;
    font-size: 18px ;
    text-transform: none !important;
    letter-spacing: 0.2px;
    margin-top: -4px !important;
  }

  .btn-arrowLabel:hover {
    color:#000;
  }

  .btn-headerback {
    background-color: transparent;
    display: block;
    text-align: left;
    padding-left: 20px;
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    height: 100%;
  }

  .button,
  .btn-group .button {
    padding: 0.4em 0.65em;
  }

  .btn-outline-link {
    border: 1px solid;
    border-radius: 0px;
    background-color: #ffffff;
    border-color: #000000;
    color: #000000;
  }

  .btn-outline-link.active {
    background-color: $primary-light-1 !important;
    color: #000000 !important;
  }

  .btn-outline-link {
    margin-right: 15px;
    padding: 5px 10px;
  }
  
  .btn-outline-link:last-child {
    margin-right: 0px;
  }

  .left-container {
    .btn-headerback {
      height: 50px !important;
    }
  }

  .right-container {
    .c-header-searchbar .btn {
      line-height: 20px;
      //font-size: 16px ;
    }
  }

  .rs-picker {

    .rs-picker-toggle {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: hsl(0,0%,100%);
      border-color: hsl(0,0%,80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      cursor: default;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      min-height: 38px;
      outline: 0 !important;
      position: relative;
      -webkit-transition: all 100ms;
      transition: all 100ms;
      box-sizing: border-box;
    }

    .rs-picker-toggle:focus, .rs-picker-toggle-active, .rs-picker.rs-picker-focused {
      border-color: #2684FF;
      box-shadow: 0 0 0 1px #2684FF;
    }

  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: hsl(0,0%,20%);
  }

  .left-container {
    .card-body {
      padding: 5px;
    }
  }

  .ag-theme-alpine {
    .ag-header {
      background-color: $primary-color;
    }

    .ag-full-width-container {
      .ag-header {
        background-color: $primary-light-5;
      }
    }

    .ag-cell,
    .ag-full-width-row .ag-cell-wrapper.ag-row-group {
      //padding-left: 15px !important;
      //padding-right: 15px !important;
    }

    .ag-cell[col-id="actions"] {
      padding-left: 0px !important;
    }

    .ag-full-width-row .ag-cell-wrapper.ag-cell-expandable {
      padding-left: 0px !important;
    }

    .ag-details-row {
      padding: 10px;
      
      .ag-header-viewport {
        background-color: #EEEEEE !important;
      }
      .ag-header-cell {
        padding: 0px 0px 0px 10px;
      }
    }

    .ag-layout-auto-height .ag-center-cols-clipper,
    .ag-layout-auto-height .ag-center-cols-container,
    .ag-layout-print .ag-center-cols-clipper,
    .ag-layout-print .ag-center-cols-container {
      min-height: auto;
    }

    .ag-ltr .ag-group-expanded, .ag-ltr .ag-group-contracted {
      margin-right: 0px;
    }

    .table-button {
      padding: 0px 10px;
    }
  }

  .availability-container,
  .performance-container,
  .quality-container,
  .planningfactor-container {

    .badge {
      display: block;
      white-space: initial;
      background-color: #F5F5F5;
    }

  }

  .oee-container {
    padding: 0px;
    padding-top: 10px;
    background-color: white;
    border-radius: 5px;

     .card {
      background-color: transparent;
      border-radius: 5px;
    }

    .oee-label {
      z-index: 1;
      //padding-top: 15px;
      //margin-left: 20px;
      font-weight: bold;
      font-size: 12px;
    }
    
    .chart-container {
      width: 100%;
      height: 170px;
      overflow-y: hidden;
    }
    
    .chart-inner-container {
      width: 100%;
      height: 250px;
    }
  }
}

@media (min-width: 576px) {
  
}

@media (min-width: 768px) {
  
}

@media (min-width: 992px) {

}

@media (min-width: 1280px) {
  .oee-dashboard-component {
    .availability-container,
    .performance-container,
    .quality-container,
    .planningfactor-container {

      .label {
        font-size: 12px;
        padding-right: 0px;
      }

      .value {
        font-size: 12px;
        padding-left: 0px;
      }

    }
  }
}
