
.Create__Page {
    .Page__Input {
        input {
            height: 50px;
            border-radius: 5px;
        }
    }
    .Parent__Tree {
        .form-group {
            .ant-select-selector {
                height: 50px;
                padding-left: 20px;
                display: flex;
                align-items: center;
                .ant-select-selection-search {
                    height: 50px;
                    input {
                        height: 50px;
                        padding-left: 20px;
                    }
                }
            }
        }
        .ant-select-selection-search {
            left: 0;
        }
    }
    .Icon__List--select {
        .Select__control {
            height: 50px;
            border-radius: 5px;
        }
        .Single__Option {
            svg {
                margin-right: 12px;
            }
        }
        .Select__value-container {
            padding-top: 0;
            padding-left: 20px;
            height: 100%;
            .Select__option  {
                padding-left: 0;
            }
        }
        .css-b8ldur-Input {
            position: absolute;
        }
    }
    .Variables__List--select {
        .Select__control {
            height: 50px;
            border-radius: 0;
        }
        .form-group {
            .ant-select-selector {
                height: 50px;
                padding-left: 20px;
                display: flex;
                align-items: center;
                .ant-select-selection-search {
                    height: 50px;
                    input {
                        height: 50px;
                        padding-left: 20px;
                    }
                }
            }
        }
        .ant-select-selection-search {
            left: 0;
        }
    }
    .Path__Url {
        .input__control {
            height: 50px;
            border-radius: 0;
        }
        .input__multi-value {
            background-color: $primary-light-2 !important;
            border: 1px solid black;
        }
    }
    .Sidebar__Switch {
        display: flex;
        align-items: center;
        .form-group {
            display: flex;
            align-items: center;
            margin: 0;
            label:nth-of-type(2) {
                margin: 0;
            }
        }
    }
    .Advanced__Switch {
        .form-group {
            display: flex;
            align-items: center;
            label:nth-of-type(2) {
                margin: 0;
            }
        }
    }
    .Widget__Categories {
        .input__control  {
            height: 50px;
            border-radius: 0;
        }
    }
    .Link__Access {
        .input__control  {
            height: 50px;
            border-radius: 0;
        }
    }
    .Entities {
        .input__control  {
            height: 50px;
            border-radius: 0;
        }
    }
    .View__Access {
        .input__control  {
            height: 50px;
            border-radius: 0;
        }
    }
    .c-switch-3d-primary .c-switch-input:checked + .c-switch-slider {
        background-color: $primary-light-3 !important;
        &::before {
            background-color: $primary-light-1 !important;
            border: 1px solid black
        }
    }
}
.Tree__Component--Footer {
    .ant-btn {
        border: none;
        background-color: $primary-color !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 12px !important;
        height: 40px !important;
        text-transform: uppercase;
        padding: 10px;
        border: 0px;
        display: flex;
        align-items: center;
        color: black !important;
        margin-right: 10px;
        :hover {
        }
    }
}
.Confirm__Delete--modal {
    .ant-spin {
        width: 100%;
    } 
}

