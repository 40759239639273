

.DynamicChart__Module--Component {
    .Actions--form {
        .Create__Edit--button {
            width: 160px;
            height: 40px;
         
            &:hover {
                background-color: $primary-color-light;
            }
            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    
    table {
        width: -webkit-fill-available;
        margin: 30px 35px;

        th {
            background-color: $primary-light-2 !important;
            height: 40px;

        }

        th:first-child {
            border-radius: 5px 0px 0px 0px;
        }

        th:last-child {
            border-radius: 0px 5px 0px 0px;
        }

        td {
            font-size: 12px !important;
            height: 40px;
            background-color: $primary-color-light;           
        }


        
        tbody > tr > td:last-child {
            display: flex;
            justify-content: flex-start;
            padding-left: 0px;
        }
        button {
            background-color: transparent;
            padding: 10px;
            border: none;
            &:focus {
                outline: none;
            }
            .c-icon {
                width: 20px;
                height: 20px;
            }
            &:hover {
                 background-color: $primary-color-light;
            }
            svg {
                margin: 0;
            }
        }
        .Editing {
            background-color: $white1-color;
        }
    }

    input#label, input#label__axis {
        padding-left: 20px;
        &:required {
            background-image: url('./../../../assets/icons/exclamation-mark.svg');
            border-color: #E2574C;
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: 95% 50%;
            padding-right: 40px;
        }
    }
    .nav-link {
        font-weight: bold;
        color: #B5B5B5;
        &.active {
            color: black;
        }
    }
    input, .Select__value-container {
        padding-left: 20px;
    }
    .form-group {
        position: relative;
        .text-muted {
            color: #E2574C !important;
            position: absolute;
            right: 0;
            display: none;
        }
    }
    .form-group > div {
        .Select__control {
            border-radius: 5px;
            height: 40px;
            align-items: center;
            .Select__placeholder, .Select__single-value {
                margin-left: 3px;
                top: 50%;
            }
            .Select__value-container {
                //height: 100%;
                padding-left: 20px;
                height: 40px;
                padding-top: 0;
                font-size: 12px;
                position: relative;
                .Select__input {
                    margin: 0;
                    height: 40px;
                    input {
                        height: 40px;
                    }
                }
                .css-b8ldur-Input {
                    height: 50%;
                    margin-top: 0;
                    margin-bottom: 0;
                    top: 0;
                    font-size: 12px;
                    position: absolute;
                    background-color: transparent;
                 //   background-color: red;
                }
            }
            &--is-focused {
                border-color: inherit;
                box-shadow: inherit;
            }
        }
        .Select__menu {
            border-radius: 0px;
            padding: 0;
            top: 50px;
            margin: 0;
            //height: fit-content;
        }
        .css-b8ldur-Input {
            left: 16px;
        }
        .Select__menu-list {
            top: 0px;
            padding: 0px;
            height: auto;
        }
        .Select__indicator-separator {
            background-color: #fff;
        }
        .Select__option {
            height: 40px;
            border: solid #ccc;
            border-width: 1px;
            border-top-width: 0px;
            font-size: 12px;
            &:hover {
                background-color: $black4-color;
            }
        }
        .Select__option--is-selected  {
            background-color: #fff;
            color: inherit;
            font-weight: bold;
        }
        .Select__option--is-focused  {
            background-color: $black4-color;
        }
        }
    > ul {
        font-size: 12px;
        color: #B5B5B5;
    }
    .custom-select option {
            margin: 16px !important;
        }
        .option {
            height: 40px;
            margin: 16px !important;
        }
    .filter-tokenizer, .padding-for-clear-all {
        padding: 0 !important;
        height: 40px;
        .typeahead {
            padding: 0;
        }
        .filter-input-group {
            overflow-y: hidden;
        }
    }
    display: flex;
    flex-direction: column;
    .nav-tabs {
        margin: 20px 20px 0px 20px;
        width: fit-content;
        padding-bottom: 0px;
        > :first-child {
            border-right: 2px solid $black1-color;
        }
        .active {
            font-weight: bold;
        }
    } 
    .row {
        justify-content: space-between;
        align-items: center;
        input, select, .token-collection {
            height: 40px;
            &:focus {
                background-color: white !important;
            }
        }
    }
    .col-6 {
        height: 100%;
    }
    .col-12 {
        padding: 0;
    }
    .Switches__Button--Block {
        .form-group {
            margin: auto;
            display: flex;
            align-content: center;
            label {
                margin: auto;
                color: $black2-color;
            }
        }
        > :last-child {
            display: flex;
            justify-content: flex-end;
        }
        .Cancel__Button {
            background-color: $black4-color;
            margin-right: 16px;
        }
    }
    label {
        margin-top: 10px;
        font-size: 12px;
        font-weight: bold;
    }
    .Options__Inputs--Section {
        .form-group {
            width: 100%;
        } 
    }
    
    .tab-content {
        width: -webkit-fill-available;
    };
    .Options__Inputs--Section, .Axis__Configuration--div {
        padding: 0px 17px;
        button {
            width: 160px;
            height: 40px;
            background-color: $green1-color !important; 
            padding: 15px;
            &:hover {
                background-color: $green3-color;
            }
        }
    }
    .Axis__Configuration--tab {
        input:required {
            //Fix on Firefox
            box-shadow: initial;
        }
        .row {
            > :nth-child(2), > :nth-child(4) {
                display: flex;
                button {
                    background-color: #68DEC6;
                }
            }
            > :nth-child(4) > .form-group {
                display: flex;
                flex-direction: column;
                fieldset {
                    display: flex;
                    label {
                        margin: 0;
                    }
                }
            }
            > :nth-child(3) > span {
                position: absolute;
                color: red;
                width: max-content;
            }
            > :nth-child(5) {
                display: flex;
                justify-content: flex-end;
            }
            .form-group {
                height: 80px;
                fieldset {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
            .btn-primary {
                background-color: $black4-color; 
                margin-right: 8px;
            }
        }
        table {
            th {
                &:nth-child(-n+3) {
                    width: 100px;
                }
                &:last-child {
                    width: 100px;
                }
            }
        }
    }
    .c-form-check-input:focus, .c-switch-input:focus {
        outline-color: transparent;
        border: none;
        outline-style: none;
        outline-width: 0;
    }
    .c-switch-3d-primary .c-switch-input:checked + .c-switch-slider {
        background-color: $primary-light-2 !important;
        &::before {
            background-color: $primary-color !important;
            border: 1px solid $primary-color !important;
        }
    }
    .c-switch-slider {
        width: 50px;
        background-color: black;
    }
    .c-switch-input:checked ~ .c-switch-slider::before {
        transform: translateX(25px);
    }
    .Options__Inputs--Section.Options__Inputs--top.row  {
        margin-top: 25px;
        padding-top: 15px;
        background-color: $grey6-color;
    }
    .Options__Inputs--Section.Options__Inputs--bottom.row {
        margin-top: 0px;    
        padding-bottom: 20px;
        background-color: $grey6-color;
    }

    .Switches__Button--Block.row {
        margin-top: 20px;
        border: 0px solid $grey6-color;
        label {
            color: $grey3-color;
      
        }
        label:nth-child(2) {
            color: black;
            margin-left: 10px;
        }
    }
  
    .c-switch[class*="-3d"] .c-switch-slider {
        border: 0px;
        background-color: $grey3-color;
    }

    // data axis
    .axisField.row {
        margin-top: 25px;
        background-color: $grey6-color;
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 15px;
        padding-bottom: 30px;
        padding-top: 15px;

        .c-switch{
            margin-left: 10px;
            margin-top: -30px;
        }
        fieldset label{
            margin-top: -19px;
            margin-left: 10px;
        }
       
        .btn.btn-secondary.btn-sm {
            margin-top: 25px;
            float:right;
            
            margin-right: 0px;
        }
    }
    

    //Style Table of Series
    table {
        width: -webkit-fill-available;
        margin: 30px 35px;
        th {
            background-color: $green4-color;
        }
        td {
            font-size: 14px;
            height: 40px;
        }
        tbody > tr > td:last-child {
            display: flex;
            justify-content: flex-start;
            padding-left: 0px;
            button {
                background-color: transparent;
                padding: 10px;
                border: none;
                &:focus {
                    outline: none;
                }
                .c-icon {
                    width: 20px;
                    height: 20px;
                }
                &:hover {
                     background-color: #68DEC6;
                }
                svg {
                    margin: 0;
                }
            }
        }
    }
    .c-switch-input:focus, .c-switch-slider {
        box-shadow: none;
    }
    .min-and-max {
        >:nth-child(1) {
            padding-left: 0;
        }
        >:nth-child(2) {
            padding-right: 0;
        }
    }
    .Axis__Stacked {
        transition: background 3s;
    }
    .modal-footer {
        button {
            font-weight: bold;
            &.Save__Widget--Button {
                color: black;
                background-color: #68DEC6;
                padding: 12px 15px;
                &:hover {
                    background-color: $primary-color-hover;
                }
            }
        }
    }
    .card-body {
        padding: 0px 15px;
    }
    
}
.DynamicChart__Title {
    margin: 20px 40px;
    font-weight: bold;
}
.DynamicChart__Canvas {
    background-color: white;
}

.DynamicChart__Module--Component {
  
    .groupBy__Row {
        align-items: start;
        table {
            margin-top: 38px;
            height: 300px;
            overflow-y: scroll;
            display: block;
            border: 1px solid #E5E5E5;
            .ant-input-wrapper {
                border-bottom: 1px solid $grey1-color;
                input {
                    height: 40px;
                }
            }
            .ant-input-group-addon {
                background-color: transparent;
            }
        }
        button:hover {
            background-color: inherit;
            cursor: default;
        }
        th, td, tr {
            background-color: white !important;
            &:nth-child(2) {
                width: 100%;
            }
            
        }
        tr:first-of-type {
            position: sticky; 
            top: 0; 
            z-index: 1;
        }
        th {
            &:last-of-type {
                width: 200px;
            }
        }
        tr {
            border-top: 1px solid $grey4-color;
            background-color: white !important;
            border-bottom: 1px solid $grey4-color;
            display: block;
            :nth-child(3) {
                min-width: 416px;
            }
        }
        td > input {
            padding-left: 0;
        }
        th:nth-child(3) {
            min-width: 200px;
        }
        input {
            border: none;
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $grey2-color;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $grey2-color;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: $grey2-color;
            }
        }
    }

}

.Dynamic__Chart--View--Container {
    .full-height {
        height: 100%;

        .ant-spin-container {
            height: 100%;
        }
    }
    .Actions__Container {
        display: flex;
        height: inherit;
        padding-right: 15px;
        z-index: 1;
        .Drawer {
            width: 0px;
            overflow: hidden;
            transition: width 1s;
            background-color: white;
            overflow-x: auto;
            &.visible {
                width: 250px;
                border-left: 1px solid #DBDBDB;
            }
            .Drawer__Content--div {
                padding: 16px;
                height: -webkit-fill-available;
                hr.After__Search--hr {
                    margin-top: 4px;
                    margin-bottom: 4px;
                }
            }
            .dropdown-toggle {
                background-color: white;
            }
            .Single__Filter--Container {
                background-color: white;
                overflow-x: auto;
                height: 70%;
                height: calc(100% - 135px);
                overflow-y: auto;
                /* width */
                &::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey; 
                    border-radius: 10px;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $grey2-color; 
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: $grey1-color; 
                }
                & >button {
                    background-color: white;
                    border: 1px solid $grey3-color;
                    width: -webkit-fill-available;
                    //border: none;
                    font-weight: bold;
                    min-width: fit-content;
                    text-transform: uppercase;
                }
                .Filter__Options {
                    height: 0px;
                    overflow: hidden;
                    transition: height 1s;
                    background-color: white;
                    padding: 0px;
                    &.visible {
                        padding: 8px;
                        height: 200px;
                    }
                    input {
                        border: none;
                        border-bottom: 1px solid;
                    }
                    .Options__Container {
                        overflow: scroll;
                        max-height: 150px;
                        & > span {
                            display: flex;
                            align-items: center;
                            height: 30px;
                            .ant-checkbox-wrapper {
                                margin-right: 8px;
                            }
                            p {
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                }
            }
            .Search__Input {
                .ant-input-wrapper {
                    //border-bottom: 1px solid;
                }
                button {
                    border: none
                }
            }
            .Select__Filters {
                margin-bottom: 16px;
                min-width: max-content;
            }
            .Single__Filter--row {
                display: flex;
                align-items: center;
                padding: 8px;
                padding-left: 0;
                p {
                    padding: 0;
                    margin: 0;
                }
                label {
                    margin-right: 8px;
                }
            }
        }
        .Filter__HandleSideBar {
                border: 1px solid $grey3-color;
                background-color: white;
                width: 35px;
                padding: 0;
                outline: none;
                display: flex;
                padding-top: 20px;
                &.Open {
                    background-color: $black4-color;
                    /*font-weight: bold;*/
                }
                span {
                    display: inline-block;
                    transform: rotate(90deg);
                }

        }
    }
    .ant-spin-nested-loading, .ant-spin-container {
        height: 100%;
    }
}